import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useProceduresCaecum, useProceduresCaecumDebug } from '../aceComponents';
import useAuthorized from './useAuthorized';
import useProcTimeDetails from './useProcTimeDetails';

export default function useProcCaecum(enabled = true, debug = false) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const authorized = useAuthorized();
    const { data: caecum } = useProceduresCaecum(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: enabled && authorized },
    );
    const { data: caecumDebug } = useProceduresCaecumDebug(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: enabled && debug && authorized },
    );

    const {
        caecumTimestamp: timestamp,
        grossWithdrawalTime,
        caecumTimeRelativeToProcedureStart: timeRelativeToProcedureStart,
    } = useProcTimeDetails();
    const reached = !!caecum;

    return useMemo(
        () =>
            enabled
                ? {
                      reached,
                      timestamp,
                      timeRelativeToProcedureStart,
                      grossWithdrawalTime,
                      debug: (debug && caecumDebug) ?? null,
                  }
                : null,
        [enabled, reached, timestamp, timeRelativeToProcedureStart, grossWithdrawalTime, debug, caecumDebug],
    );
}
