import { withAsyncBoundary } from '../../aceapi/utils';
import useCaseAssessmentMgr from './hooks/useCaseAssessmentMgr';
import { useCaseSeriesListOngoing } from '../../aceapi/aceComponents';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import {
    Button,
    Dialog,
    DialogActions,
    DialogTitle,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
} from '@mui/material';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useState } from 'react';

function CaseSeriesDialog({ onClose, open }) {
    const { data: caseSeries } = useCaseSeriesListOngoing({});

    const handleListItemClick = (value) => {
        onClose(value);
    };

    return (
        <Dialog open={open}>
            <DialogTitle>Select a Case Series:</DialogTitle>
            <List sx={{ pt: 0 }}>
                {caseSeries.map((caseSeries) => (
                    <ListItem disableGutters key={caseSeries.id}>
                        <ListItemButton
                            onClick={() => handleListItemClick(caseSeries.id)}
                            sx={{
                                border: '1px solid #e0e0e0',
                                borderRadius: '4px',
                                mx: 1,
                            }}
                        >
                            <ListItemText primary={caseSeries.name} secondary={caseSeries.description} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
            <DialogActions>
                <Button onClick={() => onClose(null)} color='secondary' variant='outlined'>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    );
}

function CaseAssessmentNew() {
    const { createCase } = useCaseAssessmentMgr();

    const navigate = useNavigate();

    const [open, setOpen] = useState(false);

    const handleCaseSelect = async (caseSeriesId) => {
        setOpen(false);
        if (caseSeriesId) {
            const caseData = await createCase(caseSeriesId);
            navigate(`/assessments/${caseData.id}`);
        }
    };

    return (
        <Box display='flex' justifyContent='center' p={2}>
            <Button
                onClick={() => setOpen(true)}
                variant='contained'
                size='large'
                color='primary'
                startIcon={<PostAddIcon />}
            >
                Create new Case
            </Button>
            <CaseSeriesDialog open={open} onClose={handleCaseSelect} />
        </Box>
    );
}

export default withAsyncBoundary(CaseAssessmentNew);
