import { Button, Grid, Stack } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import useShow from '../../../aceapi/hooks/useShow';
import { useAceApp } from '../../Menu/ReportAppSelector';
import {
    useAssessmentsCasesGetAssessmentTypes,
    useAssessmentsCasesRead,
    useAssessmentsLiveFormsList,
    useProceduresComments,
    useProceduresRead,
} from '../../../aceapi/aceComponents';
import CommentSection from '../../comments/CommentSection';
import { TokenContext } from '../../../config/contexts';
import FlexBox from '../../report/FlexBox';
import { useParams } from 'react-router-dom';
import YAML from 'yaml';
import LiveAssessmentForm from './LiveAssessmentForm';
import LiveAssessmentList from './LiveAssessmentList';
import useVideoSeeker from '../../stream/useVideoSeeker';
import VideoOrStream from '../../stream/VideoOrStream';
import useProcOngoing from '../../../aceapi/hooks/useProcOngoing';
import VideoUploadProgressCard from '../../stream/VideoUploadProgressCard';
import { withAsyncBoundary } from '../../../aceapi/utils';
import Typography from '@mui/material/Typography';

function LiveAssessment(props) {
    const { app } = useAceApp();
    const { id, uuid } = useParams();
    const show = useShow();
    const token = useContext(TokenContext);
    const user = token.parse().user;
    const isStaff = user.is_active && user.is_staff;
    const userInfo = { username: user.username, isStaff };

    const { data: procData } = useProceduresRead({ pathParams: { procedureId: uuid }, queryParams: { app } });

    const { handleNext = null, handleBack = null } = props;

    const seeker = useVideoSeeker();
    const { streaming } = useProcOngoing();

    const { data: types } = useAssessmentsCasesGetAssessmentTypes({});
    const { data: currentCase } = useAssessmentsCasesRead({ pathParams: { id } });
    const { data: forms } = useAssessmentsLiveFormsList({
        queryParams: {
            categoriesFilter: currentCase?.selected_annotations?.map((ac) => ac.id),
            ...(types.find((type) => type.label === 'Standard Observation')?.id === currentCase?.type
                ? { includeAnnotations: true }
                : {}),
        },
    });

    const form = useMemo(() => {
        return forms?.[0];
    }, [forms]);

    const { data: comments } = useProceduresComments({ pathParams: { procedureId: uuid }, queryParams: { app } });

    const liveComments = useMemo(
        () =>
            comments
                ?.filter((comment) => comment.name === 'Live Reporting')
                .map((comment) => ({ ...comment, ...YAML.parse(comment.body) })),
        [comments],
    );

    const [selectedComment, setSelectedComment] = useState(() => liveComments?.[liveComments.length - 1]);

    const [unavailable, setUnavailable] = useState(false);

    return (
        <Stack spacing={3}>
            <FlexBox mt={2}>
                <Grid item xs={12} container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='h5'>
                            Procedure: {procData?.patient_id ?? procData?.procedure_id} ({procData?.username})
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <LiveAssessmentList
                            formName={form?.name}
                            comments={liveComments}
                            onSelect={setSelectedComment}
                            selectedId={selectedComment?.id}
                            seeker={seeker}
                        />
                    </Grid>
                    <Grid item xs={8}>
                        {selectedComment && <LiveAssessmentForm form={form} selectedComment={selectedComment} />}
                    </Grid>
                </Grid>
            </FlexBox>
            {show.video && !unavailable && (
                <VideoOrStream streaming={streaming} seeker={seeker} setUnavailable={setUnavailable} />
            )}
            {unavailable && !streaming && <VideoUploadProgressCard />}
            {show.comments && <CommentSection {...userInfo} seeker={seeker} />}
            {handleNext && handleBack && (
                <Stack spacing={2} direction='row'>
                    <Button onClick={handleBack}>Back</Button>
                    <Button variant='contained' onClick={handleNext}>
                        Next
                    </Button>
                </Stack>
            )}
        </Stack>
    );
}

export default withAsyncBoundary(LiveAssessment);
