import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import {
    useAnnotationsCasesCreate,
    useAnnotationsCasesUpdateAnnotationValues,
    useAnnotationsFieldsList,
    useAnnotationsValuesCreate,
    useAnnotationsValuesDelete,
} from '../../../aceapi/aceComponents';

export default function useCaseAnnotationMgr() {
    const queryClient = useQueryClient();

    const { data: fields } = useAnnotationsFieldsList({});

    const { mutateAsync: create } = useAnnotationsCasesCreate();
    const { mutateAsync: createValue } = useAnnotationsValuesCreate();
    const { mutateAsync: deleteValue } = useAnnotationsValuesDelete();
    const { mutateAsync: updateValues } = useAnnotationsCasesUpdateAnnotationValues();

    const invalidateCache = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['annotations'] });
    }, [queryClient]);

    const createCase = useCallback(
        async (procedureId) => {
            const caseData = await create({ body: { procedure_id: procedureId } });
            await invalidateCache();
            return caseData;
        },
        [create, invalidateCache],
    );

    const deleteAnnotationValue = useCallback(
        async (valueId) => {
            await deleteValue({ pathParams: { id: valueId } });
            await invalidateCache();
        },
        [deleteValue, invalidateCache],
    );

    const createAnnotationValue = useCallback(
        async (caseId, fieldId, timestamp = null, value = {}) => {
            await createValue({
                body: {
                    case: caseId,
                    field: fieldId,
                    timestamp,
                    value,
                },
            });
            await invalidateCache();
        },
        [createValue, invalidateCache],
    );

    const updateAnnotationValues = useCallback(
        async (caseId, values) => {
            await updateValues({
                pathParams: { id: caseId },
                body: {
                    values: Object.entries(values)
                        .filter(([, value]) => value !== undefined)
                        .map(([field_slug, value]) => {
                            const field = fields.find((field) => field.slug === field_slug);
                            return field.type === 'ROW'
                                ? Object.entries(value)
                                      .map(([id, rowValue]) => ({
                                          id: +id,
                                          field: field?.id,
                                          value: rowValue,
                                      }))
                                      .filter(({ value }) => value !== undefined)
                                : {
                                      field: field?.id,
                                      value,
                                  };
                        })
                        .flat(),
                },
            });
            await invalidateCache();
        },
        [updateValues, invalidateCache, fields],
    );

    return {
        createCase,
        createAnnotationValue,
        deleteAnnotationValue,
        updateAnnotationValues,
    };
}
