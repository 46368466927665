import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Widget from '../search/widgets/Widget';
import useSummaryInputs from './hooks/useSummaryInputs';

function StatOptionForm({ statName }) {
    const summaryInputs = useSummaryInputs();

    return (
        <Accordion sx={{ boxShadow: 4 }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Options</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    {summaryInputs
                        .filter((field) => !Array.isArray(field.show) || field.show.includes(statName))
                        .map((field) => (
                            <Box key={field.name}>
                                <Widget {...field} />
                            </Box>
                        ))}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}

export { StatOptionForm };
