import { withAsyncBoundary } from '../../../aceapi/utils';
import {
    useAnnotationsCasesByProcedureId,
    useAnnotationsCasesGetAnnotationValues,
    useAnnotationsFieldsList,
} from '../../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { IconButton, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { FieldComponent } from '../../assessment/common/AssessmentCommonFields';
import ConfirmButton from '../../dialogs/ConfirmButton';
import ClearIcon from '@mui/icons-material/Clear';
import useCaseAnnotationMgr from '../hooks/useCaseAnnotationMgr';
import { useFormContext } from 'react-hook-form-mui';
import Link from '@mui/material/Link';
import format from 'format-duration';
import useShow from '../../../aceapi/hooks/useShow';

function AnnotationTable({ onChange, seeker, selectedCategories }) {
    const { uuid } = useParams();
    const formContext = useFormContext();
    const show = useShow();

    const { data: caseData } = useAnnotationsCasesByProcedureId({ queryParams: { procedureId: uuid } });
    const { data: fields } = useAnnotationsFieldsList({
        queryParams: { globalField: false, ...(selectedCategories && { categoriesFilter: selectedCategories }) },
    });
    const { data: values } = useAnnotationsCasesGetAnnotationValues({ pathParams: { id: caseData.id } });

    const { deleteAnnotationValue } = useCaseAnnotationMgr();

    const filteredFields = useMemo(() => {
        return fields?.filter((field) => field.type === 'ROW');
    }, [fields]);

    const filteredValues = useMemo(() => {
        return values?.filter((value) => filteredFields.some((field) => field.id === value.field));
    }, [filteredFields, values]);

    const valuesPerField = useMemo(() => {
        return filteredValues?.reduce((acc, value) => {
            if (!acc[value.field_slug]) {
                acc[value.field_slug] = [];
            }
            acc[value.field_slug].push(value);
            return acc;
        }, {});
    }, [filteredValues]);

    const valuesGroupedBySameSubFields = useMemo(() => {
        return Object.entries(valuesPerField).reduce((acc, [field_slug, values]) => {
            const rowField = fields.find((field) => field.slug === field_slug);
            const rowFields = rowField?.row_fields ?? [];
            const rowFieldsHash = rowFields
                .sort()
                .map((subField) => subField.slug)
                .join('|');
            if (!acc[rowFieldsHash]) {
                acc[rowFieldsHash] = [];
            }
            acc[rowFieldsHash] = acc[rowFieldsHash].concat(values);
            return acc;
        }, {});
    }, [fields, valuesPerField]);

    const getCols = useCallback(
        (rowFieldSlugs) => {
            const rowFields = rowFieldSlugs.map((slug) => fields.find((field) => field.slug === slug));
            return [
                {
                    field: 'field_slug',
                    headerName: 'Field',
                    flex: 0.05,
                    minWidth: 80,
                    valueFormatter: (params) =>
                        filteredFields.find((field) => field.slug === params.value)?.name ?? params.value,
                },
                {
                    field: 'timestamp',
                    headerName: 'Time',
                    flex: 0.05,
                    minWidth: 120,
                    renderCell: (params) => {
                        const ts = params.value;
                        const frame = seeker.ts2frame(ts);
                        return (
                            <Link onClick={() => seeker.seekTo(ts)} sx={{ cursor: 'pointer' }}>
                                {format(ts - seeker.video_start)} ({frame})
                            </Link>
                        );
                    },
                },
                ...rowFields.map((subField) => ({
                    field: subField.slug,
                    headerName: subField?.name ?? 'Unknown',
                    flex: 0.1,
                    minWidth: 120,
                    renderCell: (params) => {
                        const field_slug = params.row.field_slug;
                        return (
                            <FieldComponent
                                keyField='slug'
                                field={{
                                    ...subField,
                                    slug: `${field_slug}.${params.id}.${subField.slug}`,
                                }}
                                onChange={onChange}
                                textVariant='standard'
                            />
                        );
                    },
                })),
                {
                    field: 'id',
                    headerName: 'Delete',
                    flex: 0.02,
                    minWidth: 75,
                    hide: show.case_annotations_external_restriction,
                    renderCell: (params) => (
                        <ConfirmButton
                            ButtonType={IconButton}
                            onConfirm={() => {
                                const field_slug = params.row.field_slug;
                                deleteAnnotationValue(params.id).then(() => {
                                    formContext.setValue(field_slug, {
                                        ...formContext.getValues()[field_slug],
                                        [params.id]: undefined,
                                    });
                                });
                            }}
                            color='error'
                            stopPropagation
                        >
                            <ClearIcon />
                        </ConfirmButton>
                    ),
                },
            ].filter((col) => !col?.hide);
        },
        [
            deleteAnnotationValue,
            fields,
            filteredFields,
            formContext,
            onChange,
            seeker,
            show.case_annotations_external_restriction,
        ],
    );

    return (
        <Stack spacing={2}>
            {Object.entries(valuesGroupedBySameSubFields).map(([row_fields, values]) => (
                <DataGrid
                    key={row_fields}
                    columns={getCols(row_fields ? row_fields.split('|') : [])}
                    rows={values}
                    autoHeight
                    sortModel={[{ field: 'timestamp', sort: 'asc' }]}
                />
            ))}
        </Stack>
    );
}

export default withAsyncBoundary(AnnotationTable);
