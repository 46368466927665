import React, { useCallback, useEffect, useMemo } from 'react';
import { Stack } from '@mui/material';
import { FieldComponent } from '../common/AssessmentCommonFields';
import { withAsyncBoundary } from '../../../aceapi/utils';
import { FormContainer } from 'react-hook-form-mui';
import { DataGrid } from '@mui/x-data-grid';
import { useForm } from 'react-hook-form';

function LiveAssessmentAnnotation({ key, annotationFields, annotationValues, onAnnotationChange }) {
    const getRows = useCallback(
        (annotationValues) => {
            return annotationFields.map(({ id, name, slug, row_fields }) => {
                return {
                    id,
                    name,
                    slug,
                    ...row_fields.reduce((acc, field) => {
                        acc[field.slug] = annotationValues?.[slug]?.[field.slug] ?? '';
                        return acc;
                    }, {}),
                };
            });
        },
        [annotationFields],
    );

    const rows = useMemo(() => getRows(annotationValues), [annotationValues, getRows]);
    const defaultValues = useMemo(() => Object.fromEntries(rows.map((row) => [row.slug, row])), [rows]);

    const formContext = useForm({ defaultValues });

    useEffect(() => {
        formContext.reset(defaultValues);
    }, [defaultValues, formContext, key]);

    const getCols = useCallback(
        (row_fields, slug) => {
            return [
                {
                    field: 'name',
                    headerName: 'Name',
                    flex: 1,
                    minWidth: 80,
                    sortable: false,
                },
                ...row_fields.map((subField) => ({
                    field: subField.slug,
                    headerName: subField.name,
                    flex: 1.5,
                    minWidth: 120,
                    sortable: false,
                    renderCell: () => {
                        const handleChange = (event) => {
                            onAnnotationChange(slug, subField.slug, event.target.value);
                        };

                        return (
                            <FieldComponent
                                keyField='slug'
                                field={{
                                    ...subField,
                                    slug: `${slug}.${subField.slug}`,
                                }}
                                clearable
                                onChange={handleChange}
                                textVariant='standard'
                            />
                        );
                    },
                })),
            ];
        },
        [onAnnotationChange],
    );

    return (
        <FormContainer formContext={formContext}>
            <Stack spacing={2}>
                {annotationFields.map(({ id, slug, row_fields }) => {
                    return (
                        <DataGrid
                            key={id}
                            rows={rows}
                            columns={getCols(row_fields, slug)}
                            autoHeight
                            hideFooter
                            disableColumnMenu
                            disableSelectionOnClick
                        />
                    );
                })}
            </Stack>
        </FormContainer>
    );
}

export default withAsyncBoundary(LiveAssessmentAnnotation);
