import ReactPlayer from 'react-player';
import { AsyncBoundary } from '../../aceapi/utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Grid, Paper, Skeleton, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import BoxSelectCanvas from '../canvas/BoxSelectCanvas';
import ConfirmButton from '../dialogs/ConfirmButton';
import useProcTime from '../../aceapi/hooks/useProcTime';
import VideoNavButtons from './VideoNavButtons';
import { useAceApp } from '../Menu/ReportAppSelector';
import { useProceduresDetections, useProceduresDetectionsPolygon, useVideoRead } from '../../aceapi/aceComponents';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';
import { binarySearch } from '../Charts/timeline/utils';
import useShow from '../../aceapi/hooks/useShow';

const min_delta_ts = 75;
const legacy_persistence = 500;
const normal_persistence = 50;

const box_avg = (b1, b2) => {
    return {
        ...b1,
        x1: (b1.x1 + b2.x1) / 2,
        x2: (b1.x2 + b2.x2) / 2,
        y1: (b1.y1 + b2.y1) / 2,
        y2: (b1.y2 + b2.y2) / 2,
    };
};

function preprocess(boxes) {
    if (!Array.isArray(boxes)) return [];
    if (boxes.length <= 1) return [];
    const new_boxes = [];
    let consecutive_counter = 1;
    for (let i = 1; i < boxes.length; ++i) {
        const delta_ts = boxes[i].timestamp - boxes[i - 1].timestamp;
        consecutive_counter = delta_ts < 2 * min_delta_ts ? consecutive_counter + 1 : 1;
        if (consecutive_counter >= 5) new_boxes.push(box_avg(boxes[i], boxes[i - 1]));
    }
    return new_boxes;
}

const Canvas = ({ video, legacy, uuid, timestamp, showLostBoxes, ...props }) => {
    const show = useShow();
    const { app } = useAceApp();
    const canvasRef = useRef(null);
    const { data: boxes_raw } = useProceduresDetections(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: show.cad },
    );
    const { data: polygons } = useProceduresDetectionsPolygon(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: show.cad },
    );
    const [boxes, setBoxes] = useState([]);
    const [persistence, setPersistence] = useState(50);

    useEffect(() => {
        setBoxes(legacy ? preprocess(boxes_raw) : boxes_raw);
        setPersistence(legacy ? legacy_persistence : normal_persistence);
    }, [boxes_raw, legacy]);

    const draw = useCallback(
        (canvas, ctx) => {
            const filtered_boxes = [];
            if (Array.isArray(boxes) && boxes.length > 0) {
                const idx = binarySearch(boxes, timestamp, (x, y) => x - y.timestamp);
                let box_list = [];
                if (idx >= 0 && idx < boxes.length && Math.abs(boxes[idx].timestamp - timestamp) < persistence)
                    box_list.push(boxes[idx]);
                let i = idx + 1;
                while (i < boxes.length && boxes[i].timestamp - timestamp < normal_persistence)
                    box_list.push(boxes[i++]);
                i = idx - 1;
                while (i >= 0 && timestamp - boxes[i].timestamp < persistence) box_list.push(boxes[i--]);
                box_list = box_list.sort(
                    (x, y) => Math.abs(x.timestamp - timestamp) - Math.abs(y.timestamp - timestamp),
                );
                const map = new Map();
                for (const box of box_list) {
                    if (!map.has(box.track)) {
                        map.set(box.track, true);
                        filtered_boxes.push(box);
                    }
                }
            }
            const filtered_polygons = [];
            if (Array.isArray(polygons) && polygons.length > 0) {
                const idx_poly = binarySearch(polygons, timestamp, (x, y) => x - y.timestamp);
                let polygon_list = [];
                if (
                    idx_poly >= 0 &&
                    idx_poly < polygons.length &&
                    Math.abs(polygons[idx_poly].timestamp - timestamp) < persistence
                )
                    polygon_list.push(polygons[idx_poly]);
                let i = idx_poly + 1;
                while (i < polygons.length && polygons[i].timestamp - timestamp < normal_persistence)
                    polygon_list.push(polygons[i++]);
                i = idx_poly - 1;
                while (i >= 0 && timestamp - polygons[i].timestamp < persistence) polygon_list.push(polygons[i--]);
                polygon_list = polygon_list.sort(
                    (x, y) => Math.abs(x.timestamp - timestamp) - Math.abs(y.timestamp - timestamp),
                );
                const map_poly = new Map();
                for (const polygon of polygon_list) {
                    if (!map_poly.has(polygon.track)) {
                        map_poly.set(polygon.track, true);
                        filtered_polygons.push(polygon);
                    }
                }
            }

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const dw = (video.x1 * canvas.width) / video.width;
            const dh = (video.y1 * canvas.height) / video.height;
            const imw = (canvas.width * (video.x2 - video.x1)) / video.width;
            const imh = (canvas.height * (video.y2 - video.y1)) / video.height;

            const get_x = (x) => (x * imw) / 512 + dw;
            const get_y = (y) => (y * imh) / 512 + dh;

            ctx.strokeStyle = '#a62dfb';
            ctx.lineWidth = 2;
            ctx.beginPath();
            ctx.rect(get_x(0), get_y(0), get_x(512) - get_x(0), get_y(512) - get_y(0));
            ctx.closePath();
            ctx.stroke();

            for (const box of filtered_boxes) {
                if (!showLostBoxes && box.status === 2) continue;
                const x1 = get_x(box.x1);
                const y1 = get_y(box.y1);
                const x2 = get_x(box.x2);
                const y2 = get_y(box.y2);
                // Draw the bounding box in red its status is "Lost"
                ctx.strokeStyle = box.status === 2 ? '#ff0000' : '#00ff00';
                ctx.beginPath();
                ctx.rect(x1, y1, x2 - x1, y2 - y1);
                ctx.closePath();
                ctx.stroke();
            }

            for (const poly of filtered_polygons) {
                if (!showLostBoxes && poly.status === 2) continue;
                const coords = poly.coordinates;
                if (coords.length < 3) continue;
                ctx.strokeStyle = poly.status === 2 ? '#ff0000' : '#00ff00';
                ctx.beginPath();
                ctx.moveTo(get_x(coords[0][0]), get_y(coords[0][1]));
                for (let i = 1; i < coords.length; i++) {
                    ctx.lineTo(get_x(coords[i][0]), get_y(coords[i][1]));
                }
                ctx.closePath();
                ctx.stroke();
            }
        },
        [boxes, polygons, persistence, timestamp, video, showLostBoxes],
    );

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        draw(canvas, context);
    }, [draw]);

    return <canvas ref={canvasRef} {...props} />;
};

function AsyncProcedureStream({ seeker, setVideoStatus, mouse, showLostBoxes = false, showNavButtons = false }) {
    const { app } = useAceApp();
    const { uuid } = useParams();
    const { data: video } = useVideoRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const start = video?.video?.start ?? 0;
    const legacy = start < 1627250400000; // Before the 26/07/2021
    const [fullscreenWarning, setFullscreenWarning] = useState(true);
    const ratio = (video?.video?.width ?? 16) / (video?.video?.height ?? 9);
    const drawMode = mouse?.mode.state === 'draw';

    const handleProgress = (seconds) => {
        const timestamp = Math.round(seconds * 1000) + start;
        seeker.timestamp.setState(timestamp);
        seeker.frame.setState(seeker.ts2frame(timestamp));
    };

    const handleFullscreen = useCallback(() => {
        if (document.fullscreenElement && fullscreenWarning) {
            const proceed = window.confirm(
                'The detection boxes overlay cannot be displayed in fullscreen mode, but you can always zoom in ' +
                    'freely.\nClick OK to ignore next time.',
            );
            if (proceed) setFullscreenWarning(false);
        }
    }, [fullscreenWarning]);

    useEffect(() => {
        setVideoStatus(video?.video?.reason);
    }, [setVideoStatus, video]);

    useEffect(() => {
        if (seeker.playerRef.state) {
            const videoPlayer = seeker.playerRef.state.getInternalPlayer();
            if (videoPlayer) {
                const events = ['fullscreenchange', 'webkitfullscreenchange', 'mozfullscreenchange'];
                events.forEach((event) => videoPlayer.addEventListener(event, handleFullscreen));
                return () => events.forEach((event) => videoPlayer.removeEventListener(event, handleFullscreen));
            }
        }
    }, [handleFullscreen, seeker.playerRef.state]);

    const handlePlayerRef = useCallback(
        (playerRef) => {
            if (playerRef && seeker.playerRef.state === null) {
                const metadata = video?.video ?? {};
                seeker.playerRef.setState(playerRef);
                seeker.videoMetadata.setState(metadata);
                seeker.timestamp.setState(metadata.start ?? 0);
            }
        },
        [seeker.playerRef, seeker.timestamp, seeker.videoMetadata, video?.video],
    );

    return (
        <>
            {video?.video?.url && (
                <div style={{ position: 'relative' }}>
                    <ReactPlayer
                        id='procedure-video'
                        url={video.video.url}
                        progressInterval={25}
                        onSeek={handleProgress}
                        onProgress={(e) => handleProgress(e.playedSeconds)}
                        controls
                        width='100%'
                        height='100%'
                        ref={handlePlayerRef}
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            zIndex: 1,
                        }}
                    />
                    <div style={{ position: 'relative' }}>
                        <Canvas
                            video={video.video}
                            legacy={legacy}
                            uuid={uuid}
                            timestamp={seeker.timestamp.state}
                            width='1000%'
                            height={`${1000 / ratio}%`}
                            showLostBoxes={showLostBoxes}
                            style={{
                                zIndex: 100,
                                position: drawMode ? 'absolute' : 'relative',
                                width: '100%',
                                height: '100%',
                                top: 0,
                                right: 0,
                                pointerEvents: 'none',
                            }}
                        />
                        {drawMode && (
                            <BoxSelectCanvas
                                width='500%'
                                height={`${500 / ratio}%`}
                                video={video.video}
                                setBox={mouse.box.setState}
                                style={{
                                    zIndex: 100,
                                    position: 'relative',
                                    width: '100%',
                                    height: '100%',
                                    top: 0,
                                    right: 0,
                                }}
                            />
                        )}
                    </div>
                </div>
            )}
            {drawMode && (
                <Stack direction='row' m={1} spacing={1}>
                    <ConfirmButton
                        variant='contained'
                        color='primary'
                        onConfirm={() => mouse.mode.setState('submit')}
                        action='send a diagnosis request with the currently selected area'
                    >
                        Submit diagnosis
                    </ConfirmButton>
                    <ConfirmButton
                        variant='contained'
                        color='secondary'
                        onConfirm={() => mouse.mode.setState('cancel')}
                        action='cancel the diagnosis and exit draw mode'
                    >
                        Cancel
                    </ConfirmButton>
                </Stack>
            )}
            {showNavButtons && <VideoNavButtons seeker={seeker} mt={0} mb={-2} />}
        </>
    );
}

export default function ProcedureStream({ noPaper, setUnavailable, ...props }) {
    const [videoStatus, setVideoStatus] = useState('OK');
    const { end, status } = useProcTime();

    useEffect(() => {
        if (videoStatus !== 'OK' && status !== 'STARTED' && end > 0) {
            const msgs = {
                NODATA: 'recording was most likely disabled for this procedure',
                INCOMPLETE: 'missing video chunks are waiting to be uploaded',
                UPLOADED: 'all video chunks have been uploaded and will soon be processed',
            };
            setUnavailable((prev) => ({
                ...prev,
                'No video': Object.keys(msgs).includes(videoStatus)
                    ? msgs[videoStatus]
                    : `Unrecognized reason: ${videoStatus}`,
            }));
        }
    }, [setUnavailable, status, end, videoStatus]);

    const contentComponent = (
        <AsyncBoundary fallback={<Skeleton variant='rounded' width='100%' height={0} sx={{ pb: '56.25%' }} />}>
            <AsyncProcedureStream {...props} setVideoStatus={setVideoStatus} />
        </AsyncBoundary>
    );

    return (
        <Grid item xs={12}>
            {noPaper ? (
                contentComponent
            ) : (
                <Paper sx={{ p: 2, flexGrow: 1 }}>
                    {videoStatus === 'OK' ? (
                        contentComponent
                    ) : (
                        <Box
                            justifyContent='center'
                            alignItems='center'
                            sx={{
                                display: 'flex',
                                bgcolor: 'grey.600',
                                width: '100%',
                                aspectRatio: '16 / 9',
                            }}
                        >
                            <VideocamOffIcon
                                sx={{
                                    fontSize: 64,
                                    color: 'grey.400',
                                }}
                            />
                        </Box>
                    )}
                </Paper>
            )}
        </Grid>
    );
}
