import PaperCard from '../components/report/PaperCard';
import useShow from '../aceapi/hooks/useShow';
import Forbidden from '../components/Login/Forbidden';
import CaseAnnotationMain from '../components/annotation/CaseAnnotationMain';

export default function CaseAnnotation(props) {
    const show = useShow();

    return !show.case_annotations ? (
        <Forbidden />
    ) : (
        <PaperCard title='Annotation' xs={12} variant='h3' align='center'>
            <CaseAnnotationMain {...props} />
        </PaperCard>
    );
}
