import React from 'react';
import { Link } from 'react-router-dom';
import { ListItemText } from '@mui/material';

// Helper function to detect a UUID.
// Simple version using a standard UUID v4 regex:
const isUUID = (str) => {
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
    return uuidRegex.test(str);
};

const isImageUrl = (str) => /\.(png|jpg|jpeg|gif|webp|svg)$/i.test(str);

const isUrl = (str) => {
    try {
        // Attempt to construct a URL object. Will throw if invalid.
        new URL(str);
        return true;
    } catch (e) {
        return false;
    }
};

// Helper function to detect an email address.
// Note: Real-world email validation is more complex, but this is a good start.
const isEmail = (str) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(str);
};

// Helper function to check if a number looks like an epoch timestamp (in seconds).
// You can adjust the range or check for ms vs. s as needed.
const isEpochTimestamp = (num) => {
    // Heuristic: if it's in the range of Unix time in seconds for ~1970-2038
    // and not obviously a small integer, treat it as an epoch in seconds.
    return num > 1000000000 && num < 3000000000;
};

// Convert epoch to a human-readable date. Adjust for seconds vs milliseconds:
const formatEpoch = (num) => {
    // If you need milliseconds instead of seconds, remove the "* 1000"
    return new Date(num).toLocaleString();
};

// Helper function to detect if the number is probably a byte size
// (Adjust as needed: you might use 'key' to help with detection, e.g. if key.toLowerCase().includes('size'))
const isByteSize = (num) => num >= 0; // For demonstration, treat any non-negative as a size

// Format the byte size to MB or GB (rounded to 2 decimals)
const formatByteSize = (num) => {
    const MB = 1_000_000;
    const GB = 1_000_000_000;
    if (num < MB) {
        // If you'd like to show KB:
        // const KB = 1000;
        // return `${(num / KB).toFixed(2)} KB`;
        // Otherwise just show raw bytes:
        return `${num} bytes`;
    } else if (num < GB) {
        return `${(num / MB).toFixed(2)} MB`;
    } else {
        return `${(num / GB).toFixed(2)} GB`;
    }
};

/**
 * A small wrapper for ListItemText that attempts to format the `value` based on type detection:
 *  - Epoch timestamps => formatted date string
 *  - Data size in bytes => MB or GB
 *  - UUID => clickable link to /p/<uuid>
 *  - Email => clickable mailto link
 *
 * Usage:
 *   <SmartListItemText k="timestamp" value={1674858153} />
 */
const SmartListItemText = ({ k, value }) => {
    let displayValue;

    if (typeof value === 'number' && k !== 'id' && !k.includes('user')) {
        // Number detection
        if (isEpochTimestamp(value) || k === 'start' || k === 'end') {
            displayValue = formatEpoch(value);
        } else if (isByteSize(value) || k.startsWith('nb')) {
            displayValue = formatByteSize(value);
        } else {
            // Fallback
            displayValue = value.toString();
        }
    } else if (typeof value === 'string') {
        // String detection
        if (isUUID(value)) {
            // Link to /p/<uuid>
            displayValue = (
                <Link to={`/procedures/${value}`} style={{ textDecoration: 'none', color: 'blue' }}>
                    {value}
                </Link>
            );
        } else if (isUrl(value)) {
            if (isImageUrl(value)) {
                displayValue = <img src={value} alt={k} style={{ maxHeight: 200, maxWidth: 200 }} />;
            } else {
                // Show as a clickable link
                displayValue = (
                    <a href={value} target='_blank' rel='noopener noreferrer'>
                        {value}
                    </a>
                );
            }
        } else if (isEmail(value)) {
            // Clickable email link
            displayValue = <a href={`mailto:${value}`}>{value}</a>;
        } else {
            // Fallback
            displayValue = value;
        }
    } else {
        // Fallback for other data types, e.g. object/array
        displayValue = JSON.stringify(value);
    }

    return (
        <ListItemText
            primary={k}
            secondary={displayValue}
            // If you prefer "key: value" in a single line, you can do:
            // primary={`${k}: ${typeof displayValue === 'string' ? displayValue : ''}`}
            // and handle the non-string display values differently
        />
    );
};

export default SmartListItemText;
