import HelpIcon from '@mui/icons-material/Help';
import { Skeleton, Stack, Tooltip } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import format from 'format-duration';
import { useAvgRead } from '../../aceapi/aceComponents';
import useProcTimeDetails from '../../aceapi/hooks/useProcTimeDetails';
import { AsyncBoundary } from '../../aceapi/utils';
import { useAceApp } from '../Menu/ReportAppSelector';
import PaperCard from './PaperCard';
import { polypCountPerDiagnosis } from './utils';

const TIMESTAMP_RELATIVE_TO_ENDOSCOPE_INSERTION_STRING = 'Timestamp relative to endoscope insertion time';

const SummaryPaper = ({ title, duration, helpText }) => (
    <PaperCard xs={6} p={3} paperProps={{ variant: 'outlined' }} paperStyle={{ backgroundColor: '#eeeeee' }}>
        <Typography variant='body1' align='center'>
            <b>{title}</b>
            {helpText && (
                <Tooltip title={helpText}>
                    <HelpIcon color='primary' fontSize='small' />
                </Tooltip>
            )}
            <br />
            {duration ? format(duration) : '-'}
        </Typography>
    </PaperCard>
);

function AsyncShortEventSummary({ uuid, frames, caq = false }) {
    const { app } = useAceApp();
    const polypCounts = polypCountPerDiagnosis(frames).filter(([diagnosis]) => diagnosis !== 'uncertain');
    const { data: avgVM } = useAvgRead({
        pathParams: { procedureId: uuid },
        queryParams: { app, plot: 'visible_mucosa' },
    });

    const { retroflexionTimestamp, bodyEnterTimestamp, insertionTime, grossWithdrawalTime, totalEndoscopyTime } =
        useProcTimeDetails();
    const retroflexionTimeRelativeToInsertion =
        retroflexionTimestamp && bodyEnterTimestamp && retroflexionTimestamp - bodyEnterTimestamp;

    return (
        <div>
            {caq ? (
                <Grid container spacing={1}>
                    <SummaryPaper title='Gross withdrawal time' duration={grossWithdrawalTime} />
                    <SummaryPaper title='Total endoscope time' duration={totalEndoscopyTime} />
                    <SummaryPaper
                        title='Caecum'
                        duration={insertionTime}
                        helpText={TIMESTAMP_RELATIVE_TO_ENDOSCOPE_INSERTION_STRING}
                    />
                    <SummaryPaper
                        title='Retroflexion'
                        duration={retroflexionTimeRelativeToInsertion}
                        helpText={TIMESTAMP_RELATIVE_TO_ENDOSCOPE_INSERTION_STRING}
                    />
                </Grid>
            ) : (
                <>
                    <Typography variant='h5' color='primary.dark' align='center'>
                        Summary
                    </Typography>
                    {polypCounts.map(([diagnosis, count]) => (
                        <Typography key={diagnosis} variant='body1' align='center'>
                            {count} {diagnosis}
                        </Typography>
                    ))}
                    <Typography variant='body1' align='center'>
                        Average VM: {Math.round(avgVM.average * 100) / 100}%
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Caecal intubation: {insertionTime ? 'yes' : 'no'}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Withdrawal time: {grossWithdrawalTime ? format(grossWithdrawalTime) : '-'}
                    </Typography>
                    <Typography variant='body1' align='center'>
                        Retroflexion:{' '}
                        {retroflexionTimeRelativeToInsertion ? (
                            <>
                                {format(retroflexionTimeRelativeToInsertion)}
                                <Tooltip title={TIMESTAMP_RELATIVE_TO_ENDOSCOPE_INSERTION_STRING}>
                                    <HelpIcon color='primary' fontSize='small' />
                                </Tooltip>
                            </>
                        ) : (
                            'no'
                        )}
                    </Typography>
                </>
            )}
        </div>
    );
}

export default function ShortEventSummary(props) {
    return (
        <AsyncBoundary
            fallback={
                <Stack width='100%' alignItems='center'>
                    <Typography variant='h5' color='primary.dark' align='center'>
                        Summary
                    </Typography>
                    {Array(5)
                        .fill()
                        .map((_, i) => (
                            <Typography key={i} variant='body1'>
                                <Skeleton width={150 + ((i * (17 * i + 1) * 36) % 150)} animation='wave' />
                            </Typography>
                        ))}
                </Stack>
            }
        >
            <AsyncShortEventSummary {...props} />
        </AsyncBoundary>
    );
}
