import { withAsyncBoundary } from '../../../aceapi/utils';
import { useAssessmentsCasesMetrics, useCaseSeriesRead } from '../../../aceapi/aceComponents';
import { useMemo } from 'react';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';

const METRICS_WEIGHT = 'duration';

const getMetricAvg = (metrics, metric_name) => {
    const totalWeight = metrics.reduce((acc, metric) => acc + metric[METRICS_WEIGHT], 0);
    return metrics.reduce((acc, metric) => acc + metric[metric_name] * (metric[METRICS_WEIGHT] / totalWeight), 0);
};

function MaCaseSeriesNetworkStats({ csId }) {
    const { data: caseSeries } = useCaseSeriesRead({ pathParams: { id: csId } });
    const { data: caseMetrics } = useAssessmentsCasesMetrics({ queryParams: { caseSeries: csId } });
    const allMetrics = useMemo(
        () =>
            caseMetrics
                .map((cm) => cm.data)
                .filter((data) => 'network' in data)
                .map((data) => data.network),
        [caseMetrics],
    );

    const sites = useMemo(
        () =>
            caseSeries.included_groups
                .filter((g) => g.type === 'site')
                .map((site) => {
                    const siteMetrics = allMetrics.filter((m) => site.users.includes(m.username));
                    if (siteMetrics.length === 0) {
                        return null;
                    }
                    return {
                        name: site.name,
                        metrics: siteMetrics,
                        avgAvailability: getMetricAvg(siteMetrics, 'availability') * 100,
                        avgQuality: getMetricAvg(siteMetrics, 'quality'),
                    };
                })
                .filter((siteData) => siteData !== null),
        [allMetrics, caseSeries.included_groups],
    );

    const users = useMemo(
        () =>
            caseSeries.included_usernames
                .map((username) => {
                    const userMetrics = allMetrics.filter((m) => m.username === username);
                    if (userMetrics.length === 0) {
                        return null;
                    }
                    return {
                        name: username,
                        metrics: userMetrics,
                        avgAvailability: getMetricAvg(userMetrics, 'availability') * 100,
                        avgQuality: getMetricAvg(userMetrics, 'quality'),
                    };
                })
                .filter((userData) => userData !== null),
        [allMetrics, caseSeries.included_usernames],
    );

    const globalAvgAvailability = getMetricAvg(allMetrics, 'availability') * 100;
    const globalAvgQuality = getMetricAvg(allMetrics, 'quality');

    return (
        <Stack>
            <Typography variant='h5'>Network Metrics</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Site/User</TableCell>
                        <TableCell>Avg Availability</TableCell>
                        <TableCell>Avg Quality</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Global</TableCell>
                        <TableCell>{globalAvgAvailability.toFixed(2)}%</TableCell>
                        <TableCell>{globalAvgQuality.toFixed(2)}</TableCell>
                    </TableRow>
                    {sites.map((site) => (
                        <TableRow key={site.name}>
                            <TableCell>
                                <b>[Site]</b> {site.name}
                            </TableCell>
                            <TableCell>{site.avgAvailability.toFixed(2)}%</TableCell>
                            <TableCell>{site.avgQuality.toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                    {users.map((user) => (
                        <TableRow key={user.name}>
                            <TableCell>
                                <b>[User]</b> {user.name}
                            </TableCell>
                            <TableCell>{user.avgAvailability.toFixed(2)}%</TableCell>
                            <TableCell>{user.avgQuality.toFixed(2)}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Stack>
    );
}

export default withAsyncBoundary(MaCaseSeriesNetworkStats);
