import {
    useAssessmentsCasesGetAssessmentTypes,
    useAssessmentsCasesList,
    useCaseSeriesList,
    useFindList,
} from '../../aceapi/aceComponents';
import { DataGrid } from '@mui/x-data-grid';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { withAsyncBoundary } from '../../aceapi/utils';
import { useCallback, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import useCaseAssessmentMgr from './hooks/useCaseAssessmentMgr';
import ConfirmButton from '../dialogs/ConfirmButton';
import { useAceApp } from '../Menu/ReportAppSelector';

const dateFormater = (date) => new Date(date).toLocaleString('en-GB');

function CaseAssessmentList() {
    const { app } = useAceApp();
    const { data: cases } = useAssessmentsCasesList({});
    const { data: types } = useAssessmentsCasesGetAssessmentTypes({});
    const { data: procs } = useFindList(
        {
            queryParams: {
                app,
                procedure_id__in: cases.filter((x) => x.procedure_id).map((x) => x.procedure_id),
                page_size: 99999,
            },
        },
        {
            enabled: cases.filter((x) => x.procedure_id).length > 0,
        },
    );
    const { data: caseSeries } = useCaseSeriesList({});

    const allProcsIds = useMemo(() => procs?.results.map((x) => x.procedure_id), [procs]);
    const filteredCases = useMemo(() => {
        return cases.filter((x) => !x.procedure_id || (allProcsIds ?? []).includes(x.procedure_id));
    }, [cases, allProcsIds]);

    const [sortModel, setSortModel] = useState([{ field: 'updated_at', sort: 'desc' }]);

    const navigate = useNavigate();

    const onRowClick = useCallback(
        (params) => {
            navigate(`/assessments/${params.row.id}`);
        },
        [navigate],
    );

    const { deleteCase } = useCaseAssessmentMgr();

    const caseColumns = useMemo(
        () => [
            { field: 'id', headerName: 'ID', flex: 0.05, minWidth: 50 },
            {
                field: 'procedure_id',
                headerName: 'Procedure ID',
                flex: 0.3,
                minWidth: 300,
                valueFormatter: (params) => params.value || 'ASSESSMENT NOT STARTED!',
                cellClassName: (params) =>
                    clsx('gg-queries-awaiting-link', {
                        highlight: !params.value,
                    }),
            },
            {
                field: 'case_series',
                headerName: 'Case Series',
                flex: 0.2,
                minWidth: 50,
                valueFormatter: ({ value }) => caseSeries?.find((x) => x.id === value)?.name ?? value,
            },
            {
                field: 'patient_id',
                headerName: 'Patient ID',
                flex: 0.2,
                minWidth: 50,
                valueGetter: (params) =>
                    procs?.results.find((x) => x.procedure_id === params.row.procedure_id)?.patient_id ?? '',
            },
            {
                field: 'type',
                headerName: 'Type',
                flex: 0.2,
                minWidth: 50,
                valueFormatter: ({ value }) => types?.[value]?.label ?? value,
            },
            {
                field: 'created_at',
                headerName: 'Created At',
                flex: 0.2,
                minWidth: 50,
                valueFormatter: (params) => dateFormater(params.value),
            },
            {
                field: 'updated_at',
                headerName: 'Updated At',
                flex: 0.2,
                minWidth: 50,
                valueFormatter: (params) => dateFormater(params.value),
            },
            { field: 'created_by', headerName: 'Created By', flex: 0.2, minWidth: 50 },
            {
                field: 'already_completed',
                headerName: 'Completed',
                flex: 0.2,
                minWidth: 10,
                renderCell: (params) => (params.row.already_completed ? <DoneIcon /> : <CloseIcon />),
            },
            {
                field: 'delete',
                headerName: 'Delete',
                flex: 0.1,
                minWidth: 100,
                renderCell: (params) => (
                    <ConfirmButton
                        variant='contained'
                        color='error'
                        action='delete this case'
                        onConfirm={() => {
                            console.log('Delete', params.row.id);
                            deleteCase(params.row.id);
                        }}
                        stopPropagation
                    >
                        Delete
                    </ConfirmButton>
                ),
            },
        ],
        [caseSeries, deleteCase, procs?.results, types],
    );

    return (
        <DataGrid
            columns={caseColumns}
            rows={filteredCases}
            autoHeight
            sortModel={sortModel}
            onSortModelChange={setSortModel}
            pageSize={10}
            rowsPerPageOptions={[10]}
            sx={{
                '& .gg-queries-awaiting-link.highlight': {
                    backgroundColor: 'rgba(255, 0, 0, 0.2)',
                },
            }}
            onRowClick={onRowClick}
        />
    );
}

export default withAsyncBoundary(CaseAssessmentList);
