import { useQueryClient } from '@tanstack/react-query';
import {
    useAssessmentsAssessmentFieldsList,
    useAssessmentsCasesByProcedureId,
    useAssessmentsCasesCreate,
    useAssessmentsCasesDelete,
    useAssessmentsCasesPartialUpdate,
    useAssessmentsCasesUpdateAssessmentAnswers,
} from '../../../aceapi/aceComponents';
import { useCallback, useState } from 'react';

export default function useCaseAssessmentMgr() {
    const queryClient = useQueryClient();

    const { data: fields } = useAssessmentsAssessmentFieldsList({});

    const [procedureId, setProcedureId] = useState(null);
    const { refetch: fetchByProcedureId } = useAssessmentsCasesByProcedureId(
        {
            queryParams: { procedureId },
        },
        { enabled: false, suspense: false },
    );
    const { mutateAsync: create } = useAssessmentsCasesCreate();
    const { mutateAsync: partialUpdate } = useAssessmentsCasesPartialUpdate();
    const { mutateAsync: updateAnswers } = useAssessmentsCasesUpdateAssessmentAnswers();
    const { mutateAsync: destroy } = useAssessmentsCasesDelete();

    const invalidateCache = useCallback(async () => {
        await queryClient.invalidateQueries({ queryKey: ['assessments', 'cases'] });
    }, [queryClient]);

    const fetchCaseByProcedureId = useCallback(
        async (procedureId) => {
            await setProcedureId(procedureId);
            const { data } = await fetchByProcedureId();
            await setProcedureId(null);
            return data;
        },
        [fetchByProcedureId],
    );

    const createCase = useCallback(
        async (caseSeriesId) => {
            const caseData = await create({ body: { case_series: caseSeriesId } });
            await invalidateCache();
            return caseData;
        },
        [create, invalidateCache],
    );

    const linkCase = useCallback(
        async (caseId, procedureId) => {
            await partialUpdate({
                pathParams: { id: caseId },
                body: {
                    procedure_id: procedureId,
                },
            });
            await invalidateCache();
        },
        [partialUpdate, invalidateCache],
    );

    const updateCase = useCallback(
        async (caseId, data) => {
            await partialUpdate({
                pathParams: { id: caseId },
                body: data,
            });
            await invalidateCache();
        },
        [partialUpdate, invalidateCache],
    );

    const updateAssessmentAnswers = useCallback(
        async (caseId, answers) => {
            const formattedAnswers = Object.entries(answers)
                .filter(([, value]) => value !== undefined)
                .map(([key, value]) => ({
                    case: caseId,
                    field: fields.find((field) => field.key === key)?.id,
                    value,
                }))
                .filter(({ field }) => field !== undefined);

            const isCompleted = answers.isCompleted ?? false;
            const priorityAnnotation = answers.priority_annotation ?? false;

            await updateAnswers({
                pathParams: { id: caseId },
                body: {
                    answers: formattedAnswers,
                    is_completed: isCompleted,
                    priority_annotation: priorityAnnotation,
                },
            });

            await invalidateCache();
        },
        [updateAnswers, invalidateCache, fields],
    );

    const deleteCase = useCallback(
        async (caseId) => {
            await destroy({
                pathParams: { id: caseId },
            });
            await invalidateCache();
        },
        [destroy, invalidateCache],
    );

    return { fetchCaseByProcedureId, createCase, linkCase, updateCase, updateAssessmentAnswers, deleteCase };
}
