import { SwitchElement, TextFieldElement } from 'react-hook-form-mui';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { MenuItem, Tooltip } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import useShow from '../../../aceapi/hooks/useShow';

const CustomSwitch = ({ id, name, label, showWarning, warningText, ...props }) => {
    const WarningSwitch = styled(SwitchElement)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: red[600],
            '&:hover': {
                backgroundColor: alpha(red[600], theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: red[600],
        },
    }));

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {showWarning ? (
                <>
                    <WarningSwitch id={id} name={name} label={label} />
                    <Tooltip
                        title={<Typography sx={{ textAlign: 'center' }}>{warningText}</Typography>}
                        arrow
                        sx={{ '& .MuiTooltip-tooltip': { textAlign: 'center' } }}
                    >
                        <HelpOutlineIcon sx={{ color: 'rgba(0, 0, 0, 0.3)' }} />
                    </Tooltip>
                </>
            ) : (
                <SwitchElement id={id} name={name} label={label} {...props} />
            )}
        </div>
    );
};

export function FieldComponent({ field, onChange, keyField = 'key', textVariant = 'outlined', clearable = false }) {
    const { type, options, name } = field;
    const fieldId = field[keyField];
    const show = useShow();

    const disabled = !field?.external && show.case_annotations_external_restriction;

    switch (type) {
        case 'TEXT':
            return (
                <TextFieldElement
                    id={field[keyField]}
                    name={field[keyField]}
                    label={field.name}
                    fullWidth
                    onChange={onChange}
                    variant={textVariant}
                    disabled={disabled}
                />
            );
        case 'NUMBER':
            return (
                <TextFieldElement
                    id={field[keyField]}
                    name={field[keyField]}
                    label={field.name}
                    fullWidth
                    type='number'
                    onChange={onChange}
                    variant={textVariant}
                    disabled={disabled}
                />
            );
        case 'BOOLEAN':
            return (
                <CustomSwitch
                    id={fieldId}
                    name={fieldId}
                    label={name}
                    showWarning={fieldId === 'priority_annotation'}
                    warningText='By enabling this, the annotation will be marked as an urgent annotation in the emails sent to the trial annotator group.'
                    disabled={disabled}
                />
            );

        case 'SELECT':
            return (
                <TextFieldElement
                    id={field[keyField]}
                    name={field[keyField]}
                    label={field.name}
                    fullWidth
                    select
                    onChange={onChange}
                    variant={textVariant}
                    disabled={disabled}
                >
                    {!Array.isArray(options) && (
                        <MenuItem value='' disabled>
                            Loading...
                        </MenuItem>
                    )}
                    {clearable && (
                        <MenuItem value=''>
                            <em>None</em>
                        </MenuItem>
                    )}
                    {options?.map((option) => (
                        <MenuItem key={option.id} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </TextFieldElement>
            );
        default:
            return <Typography color='error'>Unknown field type: {type}</Typography>;
    }
}

export default function AssessmentCommonFields({ fields, onChange, xs = 4 }) {
    return (
        <Grid container spacing={3} sx={{ width: '100%' }}>
            {fields?.map((field) => (
                <Grid item key={field.id} xs={xs}>
                    <FieldComponent field={field} onChange={onChange} />
                </Grid>
            ))}
        </Grid>
    );
}
