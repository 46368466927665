import useCommentHelper from '../../report/useCommentHelper';
import { IconButton, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ConfirmButton from '../../dialogs/ConfirmButton';
import ClearIcon from '@mui/icons-material/Clear';
import YAML from 'yaml';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import useProcTime from '../../../aceapi/hooks/useProcTime';
import format from 'format-duration';
import { getAnswerChainText } from './LiveAssessmentForm';

function LiveAssessment({ comment, onSelect, selectedId, noDelete = false, seeker }) {
    const { softDeleteComment } = useCommentHelper();

    const time = useProcTime();
    const created_at = new Date(comment.created_at);
    const relTime = created_at - (seeker.video_start || time.startDate);

    const handleSelect = onSelect ?? (() => seeker.seekTo(created_at));

    return (
        <ListItem
            key={comment.id}
            disablePadding
            secondaryAction={
                <>
                    <Link onClick={() => seeker.seekTo(created_at)}>@ {format(relTime)}</Link>
                    {!noDelete && (
                        <ConfirmButton
                            action='delete this entry'
                            ButtonType={IconButton}
                            edge='end'
                            onConfirm={() => softDeleteComment(comment.id).then(() => handleSelect(null))}
                        >
                            <ClearIcon />
                        </ConfirmButton>
                    )}
                </>
            }
        >
            <ListItemButton onClick={() => handleSelect(comment)} selected={selectedId === comment.id}>
                <ListItemText
                    primary={getAnswerChainText(comment.answer, true, true)}
                    primaryTypographyProps={{
                        sx: { textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', maxWidth: '85%' },
                    }}
                />
            </ListItemButton>
        </ListItem>
    );
}

export default function LiveAssessmentList({
    formName,
    comments,
    onSelect,
    noSelect = false,
    noInsert = false,
    ...props
}) {
    const { addComment } = useCommentHelper();

    return (
        <List
            sx={{
                width: '100%',
                border: '1px solid #ddd',
                borderRadius: '4px',
                overflowY: 'auto',
                bgcolor: '#eee',
            }}
        >
            {comments
                ?.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime())
                ?.map((comment) => (
                    <LiveAssessment
                        key={comment.id}
                        comment={comment}
                        onSelect={noSelect ? undefined : onSelect}
                        {...props}
                    />
                ))}
            {!noInsert && (
                <ListItemButton
                    onClick={() =>
                        addComment(
                            'Live Reporting',
                            YAML.stringify({
                                form: formName,
                                answer: { key: 'unknown', text: 'Unknown' },
                            }),
                        ).then((comment) => onSelect({ ...comment, ...YAML.parse(comment.body) }))
                    }
                >
                    <ListItemIcon>
                        <AddIcon />
                    </ListItemIcon>
                    <ListItemText primary='Add New' />
                </ListItemButton>
            )}
        </List>
    );
}
