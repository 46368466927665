import { withAsyncBoundary } from '../../../aceapi/utils';
import { useCaseSeriesList } from '../../../aceapi/aceComponents';
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

function MACaseSeriesList() {
    const { data: caseSeries } = useCaseSeriesList({});

    const now = Date.now();
    const total = caseSeries?.length ?? '...';
    const totalActive =
        caseSeries?.filter((cs) => now >= new Date(cs.start_date) && now <= new Date(cs.end_date)).length ?? '...';

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Case Series Metrics</TableCell>
                    <TableCell>Value</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow>
                    <TableCell>Total Case Series</TableCell>
                    <TableCell>{total}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Total Active Case Series</TableCell>
                    <TableCell>{totalActive}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    );
}

export default withAsyncBoundary(MACaseSeriesList);
