import PreAssessmentForm from './pre/PreAssessmentForm';
import { useParams } from 'react-router-dom';
import { useAssessmentsCasesRead, useAssessmentsCasesGetAssessmentAnswers } from '../../aceapi/aceComponents';
import { useMemo, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import { Button, Stack, Step, StepButton, Stepper } from '@mui/material';
import Typography from '@mui/material/Typography';
import CaseAssessmentLink from './link/CaseAssessmentLink';
import LiveAssessmentMain from './live/LiveAssessmentMain';
import { useSearchParams } from 'react-router-dom';
import PostAssessmentForm from './post/PostAssessmentForm';

const ASSESSMENT_STEPS = [
    {
        key: 'pre',
        name: 'Pre-Case Assessment',
        Component: PreAssessmentForm,
    },
    {
        key: 'link',
        name: 'Link Assessment to Procedure',
        Component: CaseAssessmentLink,
    },
    {
        key: 'live',
        name: 'Live Case Assessment',
        Component: LiveAssessmentMain,
    },
    {
        key: 'post',
        name: 'Post-Case Report and Actions',
        Component: PostAssessmentForm,
    },
];

export default function CaseAssessmentSteps() {
    const { id } = useParams();
    const { data: caseData } = useAssessmentsCasesRead({ pathParams: { id } });
    const { data: answers } = useAssessmentsCasesGetAssessmentAnswers({
        pathParams: { id },
        queryParams: { stage: 'PRE' },
    });

    const [searchParams, setSearchParams] = useSearchParams();

    const [activeStep, setActiveStep] = useState(() => {
        /// If the step is set as an argument, redirect to that step
        /// (Otherwise it means the user is coming from the case list page)
        const step = searchParams.get('step');
        if (step) return ASSESSMENT_STEPS.findIndex((s) => s.key === step);

        /// If the case assessment type is not set, redirect to first step
        if (caseData.type === 0) return 0;

        if (!caseData.procedure_id) {
            /// If the pre-case assessment is complete, redirect to second step
            if (answers.length > 4 && caseData.selected_annotations.length > 0) return 1;
            /// Else redirect to first step
            return 0;
        } else {
            /// If the case is already completed, redirect to last step
            if (caseData.already_completed) return 3;
            /// Else redirect to the live case assessment
            return 2;
        }
    });

    const activeFormRef = useRef(null);

    const handleStep = (step) => async () => {
        const stepIndex = ASSESSMENT_STEPS.findIndex((s) => s.key === step.key);
        if (caseData.type === 0 && stepIndex > 0) {
            alert('You must select a case type before proceeding to step 2, 3, and 4.');
            return;
        }
        if (!caseData.procedure_id && stepIndex > 1) {
            alert('You must link an assessment to a procedure before proceeding to step 3 and 4.');
            return;
        }
        if (activeFormRef.current && typeof activeFormRef.current.submit === 'function') {
            await activeFormRef.current.submit();
        }
        setActiveStep(stepIndex);
        setSearchParams({ step: step.key });
    };

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSearchParams({ step: ASSESSMENT_STEPS[activeStep + 1].key });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setSearchParams({ step: ASSESSMENT_STEPS[activeStep - 1].key });
    };

    const ActiveComponent = useMemo(() => ASSESSMENT_STEPS[activeStep]?.Component, [activeStep]);

    // Only pass the ref if the active step is one of the form steps (pre and post)
    const isFormStep = activeStep === 0 || activeStep === 3;

    return (
        <Box sx={{ width: '100%' }} mt={2}>
            <Stack spacing={2}>
                <Stepper nonLinear activeStep={activeStep}>
                    {ASSESSMENT_STEPS.map((step) => (
                        <Step key={step.name}>
                            <StepButton onClick={handleStep(step)}>{step.name}</StepButton>
                        </Step>
                    ))}
                </Stepper>
                {ActiveComponent ? (
                    isFormStep ? (
                        <ActiveComponent
                            ref={activeFormRef}
                            handleNext={handleNext}
                            handleBack={handleBack}
                            step={activeStep}
                            totalSteps={ASSESSMENT_STEPS.length}
                        />
                    ) : (
                        <ActiveComponent
                            handleNext={handleNext}
                            handleBack={handleBack}
                            step={activeStep}
                            totalSteps={ASSESSMENT_STEPS.length}
                        />
                    )
                ) : (
                    <>
                        <Typography>Step {activeStep + 1}</Typography>
                        <Stack spacing={2} direction='row'>
                            <Button disabled={activeStep === 0} onClick={handleBack}>
                                Back
                            </Button>
                            <Button variant='contained' onClick={handleNext}>
                                {activeStep === ASSESSMENT_STEPS.length - 1 ? 'Finish' : 'Next'}
                            </Button>
                        </Stack>
                    </>
                )}
            </Stack>
        </Box>
    );
}
