import { Divider, Grid, List, ListItem, Stack, ThemeProvider, Typography } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import format from 'format-duration';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useProceduresRead } from '../aceapi/aceComponents';
import useAuthorized from '../aceapi/hooks/useAuthorized';
import useProcOngoing from '../aceapi/hooks/useProcOngoing';
import useProcTime from '../aceapi/hooks/useProcTime';
import useProcTimeDetails from '../aceapi/hooks/useProcTimeDetails';
import useShow from '../aceapi/hooks/useShow';
import BowelChart from '../components/Charts/BowelChart';
import EventTimeline from '../components/Charts/EventTimeline';
import Chart from '../components/Charts/GraphChart';
import RadialChart from '../components/Charts/RadialChart';
import ReportTimeline from '../components/Charts/ReportTimeline';
import FrameGrid from '../components/Charts/timeline/FrameGrid';
import Forbidden from '../components/Login/Forbidden';
import { useAceApp } from '../components/Menu/ReportAppSelector';
import ExtraDataLegend from '../components/report/ExtraDataLegend';
import PaperCard from '../components/report/PaperCard';
import ProcedureLoading from '../components/report/ProcedureLoading';
import ShortEventSummary from '../components/report/ShortEventSummary';
import useExtraData from '../components/report/useExtraData';
import useFrames from '../components/report/useFrames';
import useVideoSeeker from '../components/stream/useVideoSeeker';
import VideoOrStream from '../components/stream/VideoOrStream';
import { themeOptions } from '../config/themes/pastel';

export default function ProcedureCompact() {
    const { app } = useAceApp();
    const { uuid } = useParams();

    const show = useShow();

    const [unavailable, setUnavailable] = useState({});
    const [loading, setLoading] = useState({
        timeline: show.timeline,
        visible_mucosa: show.visible_mucosa,
    });
    const [update, setUpdate] = useState(false);
    const seeker = useVideoSeeker();

    const loadedCallback = (name) => () =>
        loading[name] && setLoading((prevState) => ({ ...prevState, [name]: false }));

    const authorized = useAuthorized();

    const { data: proc } = useProceduresRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized },
    );

    const time = useProcTime();
    const {
        caecumTimestamp,
        grossWithdrawalTime,
        cleaningTime,
        insertionTime,
        interventionTime,
        totalEndoscopyTime,
        totalTime,
    } = useProcTimeDetails();

    const { extraModels } = useExtraData({ show: show.extra_models });

    const { streaming } = useProcOngoing();

    useEffect(() => {
        setUpdate(Object.values(loading).some((x) => x));
    }, [loading]);

    const frameProps = useFrames({
        uuid,
        setUnavailable,
        authorized,
        hideUncertain: true,
    });

    const theme = createTheme(themeOptions);

    return (
        <ThemeProvider theme={theme}>
            {!authorized ? (
                <Forbidden />
            ) : (
                <Grid container spacing={2} alignItems='stretch'>
                    {update && <ProcedureLoading update={update} />}
                    <Grid item xs={8} container spacing={2}>
                        <PaperCard xs={6}>
                            <VideoOrStream streaming={streaming} seeker={seeker} setUnavailable={setUnavailable} />
                        </PaperCard>
                        <Grid item xs={6} container spacing={2}>
                            <PaperCard xs={12}>
                                <Grid container>
                                    <Grid item xs={6} height='4rem'>
                                        <img
                                            src={
                                                proc.hospital_logo ||
                                                'https://odin-vision.com/wp-content/webp-express/webp-images/uploads/2022/03/logo-1-181x115.png.webp'
                                            }
                                            alt='hospital_logo'
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        {proc.patient_id && (
                                            <Typography variant='h5' align='center'>
                                                {proc.patient_id}
                                            </Typography>
                                        )}
                                    </Grid>
                                    {show.procedure_card && (
                                        <>
                                            <Grid item xs={6}>
                                                <Typography align='left'>
                                                    <b>Room</b>: {proc.hospital_room ?? 'unknown'}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align='left'>
                                                    <b>User</b>: {proc.username}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align='left'>
                                                    <b>Start</b>:{' '}
                                                    {time.startDate.toLocaleString('en-GB', {
                                                        dateStyle: 'short',
                                                        timeStyle: 'short',
                                                    })}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Typography align='left'>
                                                    <b>{time.estimate ? 'Last data from' : 'End'}</b>:{' '}
                                                    {time.endDate.toLocaleTimeString('en-GB', {
                                                        timeStyle: 'short',
                                                    })}
                                                </Typography>
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                            </PaperCard>
                            <PaperCard xs={12}>
                                <FrameGrid maxHeight='7rem' {...frameProps} />
                            </PaperCard>
                        </Grid>
                        {show.timeline && (
                            <Grid item xs={12}>
                                <PaperCard xs={12} p={1}>
                                    <ReportTimeline
                                        setUnavailable={setUnavailable}
                                        loaded={loadedCallback('timeline')}
                                        seeker={seeker}
                                        stepGrowing={streaming}
                                        disablePinning
                                        toggleBars
                                        compact
                                        {...frameProps}
                                    />
                                </PaperCard>
                            </Grid>
                        )}
                        <Grid item xs={7} container spacing={2}>
                            <PaperCard xs={6}>
                                <Typography variant='h6'>Annotation Legend</Typography>
                                <ExtraDataLegend extraModels={extraModels} />
                                <Divider sx={{ m: 2 }} />
                                <Typography variant='h6'>Misc Info</Typography>
                                {show.procedure_card && (
                                    <List dense>
                                        {time.estimate && (
                                            <ListItem>
                                                <Typography>
                                                    <b>{time.getStatusMessage()}</b>
                                                </Typography>
                                            </ListItem>
                                        )}
                                        {Object.entries(unavailable).map(([name, reason]) => (
                                            <ListItem key={name}>
                                                <Typography>
                                                    <b>{name}</b>: {reason}
                                                </Typography>
                                            </ListItem>
                                        ))}
                                        {!time.estimate && Object.keys(unavailable).length === 0 && (
                                            <ListItem>
                                                <Typography>No additional information</Typography>
                                            </ListItem>
                                        )}
                                    </List>
                                )}
                            </PaperCard>
                            <PaperCard xs={6}>
                                <RadialChart
                                    extraData={[
                                        ...(caecumTimestamp
                                            ? [
                                                  {
                                                      name: 'Insertion Time',
                                                      value: insertionTime,
                                                      fill: theme.palette.secondary.main,
                                                  },
                                                  {
                                                      name: 'Gross Withdrawal Time',
                                                      value: grossWithdrawalTime,
                                                      fill: theme.palette.success.main,
                                                  },
                                              ]
                                            : []),
                                        ...(cleaningTime && interventionTime
                                            ? [
                                                  {
                                                      name: 'Cleaning and Intervention Time',
                                                      value: cleaningTime + interventionTime,
                                                      fill: theme.palette.warning.main,
                                                  },
                                              ]
                                            : []),
                                        {
                                            name: 'Total Endoscopy Time',
                                            value: totalEndoscopyTime,
                                            fill: theme.palette.error.main,
                                        },
                                        {
                                            name: 'Total Time',
                                            value: totalTime,
                                            fill: theme.palette.primary.main,
                                        },
                                    ]}
                                    cy='33%'
                                    innerRadius='40%'
                                    outerRadius='130%'
                                    barSize={18}
                                    formatter={(e) => format(e)}
                                    legendVerticalAlign='bottom'
                                    legendAlign='center'
                                />
                            </PaperCard>
                            <PaperCard xs={12}>
                                {show.visible_mucosa && (
                                    <Chart
                                        name='Visible Mucosa'
                                        aspect={4}
                                        plot='visible_mucosa'
                                        sampling={100}
                                        aggregate='mean'
                                        setUnavailable={setUnavailable}
                                        loaded={loadedCallback('visible_mucosa')}
                                        showCaecum
                                        unit='%'
                                        smartTicks
                                        compact
                                        stream={streaming}
                                    />
                                )}
                            </PaperCard>
                        </Grid>
                        <PaperCard xs={5}>
                            <BowelChart uuid={uuid} frameProps={frameProps} />
                        </PaperCard>
                    </Grid>
                    <PaperCard xs={4}>
                        <Stack direction='column' justifyContent='space-between' height='100%'>
                            <ShortEventSummary frames={frameProps.frames} uuid={uuid} />
                            <EventTimeline
                                frames={frameProps.frames}
                                maxHeight='42rem'
                                seeker={seeker}
                                startExpanded
                                fixedState
                            />
                        </Stack>
                    </PaperCard>
                </Grid>
            )}
        </ThemeProvider>
    );
}
