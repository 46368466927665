import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

export default function FinalFormChart(props) {
    const { data } = props;

    return (
        <Box sx={{ padding: 1 }}>
            <Typography variant='h5' gutterBottom>
                Network Statistics Tests
            </Typography>

            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Network Outcome</TableCell>
                            <TableCell>Video Outcome</TableCell>
                            <TableCell>Installer Name</TableCell>
                            <TableCell>Room Number</TableCell>
                            <TableCell>Network Port</TableCell>
                            <TableCell>Comments</TableCell>
                            <TableCell>UUID</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow key={item.id} id={`installation_${item.network_test}`}>
                                <TableCell>{item.network_test}</TableCell>
                                <TableCell>{new Date(item.timestamp).toLocaleString()}</TableCell>
                                <TableCell>{item.network_check_outcome}</TableCell>
                                <TableCell>{item.video_check_outcome}</TableCell>
                                <TableCell>{item.installer_name}</TableCell>
                                <TableCell>{item.room_number}</TableCell>
                                <TableCell>{item.network_port}</TableCell>
                                <TableCell>{item.comments}</TableCell>
                                <TableCell>{item.installation_id}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
}
