import { Button, Stack } from '@mui/material';
import { withAsyncBoundary } from '../../../aceapi/utils';
import {
    useAssessmentsAssessmentFieldsList,
    useAssessmentsCasesGetAssessmentAnswers,
    useAssessmentsCasesRead,
} from '../../../aceapi/aceComponents';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import useCaseAssessmentMgr from '../hooks/useCaseAssessmentMgr';
import AssessmentCommonFields from '../common/AssessmentCommonFields';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const PostAssessmentForm = forwardRef((props, ref) => {
    const { id, uuid } = useParams();
    const { handleBack } = props;
    const navigate = useNavigate();
    const { data: currentCase } = useAssessmentsCasesRead({ pathParams: { id } });
    const { updateAssessmentAnswers } = useCaseAssessmentMgr();

    const { data: fields } = useAssessmentsAssessmentFieldsList({
        queryParams: { stage: 'POST' },
    });
    const { data: answers } = useAssessmentsCasesGetAssessmentAnswers({
        pathParams: { id },
        queryParams: { stage: 'POST' },
    });

    const commonFieldsDefaultValues = useMemo(
        () => Object.fromEntries(answers?.map(({ field_key, value }) => [field_key, value]) ?? []),
        [answers],
    );

    const formContext = useForm({
        defaultValues: {
            ...commonFieldsDefaultValues,
        },
    });
    const { handleSubmit } = formContext;

    const onSubmit = useCallback(
        async (data, isCompleting = false) => {
            await updateAssessmentAnswers(id, { ...data, isCompleted: isCompleting });
        },
        [updateAssessmentAnswers, id],
    );

    const debouncedSubmit = useMemo(() => debounce((data) => onSubmit(data), 500), [onSubmit]);

    useImperativeHandle(ref, () => ({
        submit: () => {
            debouncedSubmit.flush();
            return handleSubmit((data) => onSubmit(data, false))();
        },
    }));

    return (
        <Stack spacing={2}>
            <FormContainer formContext={formContext}>
                <Stack spacing={2} p={1}>
                    <AssessmentCommonFields
                        fields={fields}
                        onChange={(data) => handleSubmit(() => debouncedSubmit(data))()}
                        xs={12}
                    />
                </Stack>
                <input type='hidden' {...formContext.register('isCompleted')} />
            </FormContainer>
            <Stack spacing={2} direction='row' alignItems='center'>
                <Button onClick={handleBack}>Back</Button>
                <Button
                    variant='contained'
                    onClick={() => handleSubmit((data) => onSubmit(data, true))().then(() => navigate('/assessments'))}
                    disabled={currentCase.type === 0}
                    startIcon={<DoneAllIcon />}
                >
                    Finish
                </Button>
                {uuid && (
                    <Button
                        variant='outlined'
                        color='secondary'
                        onClick={() =>
                            handleSubmit((data) => onSubmit(data, true))().then(navigate(`/annotation/${uuid}`))
                        }
                    >
                        Finish and go to Annotation
                    </Button>
                )}
            </Stack>
        </Stack>
    );
});

PostAssessmentForm.displayName = 'PostAssessmentForm';

export default withAsyncBoundary(PostAssessmentForm);
