import React, { useMemo } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer } from 'recharts';
import { Box, Typography } from '@mui/material';

// Helper function to generate an array of dates for the current week
const generateCurrentWeekRange = () => {
    const dates = [];
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure start of the day

    for (let i = 13; i >= 0; i--) {
        const date = new Date(today);
        date.setDate(today.getDate() - i); // Subtract i days to get the full week
        dates.push(date.toISOString().split('T')[0]); // Format: YYYY-MM-DD
    }
    return dates;
};

const ProcedurePlot = ({ data }) => {
    const binnedData = useMemo(() => {
        // Step 1: Bin data by day
        const bins = data.reduce((acc, procedure) => {
            const date = new Date(procedure.start).toISOString().split('T')[0]; // Get YYYY-MM-DD format
            acc[date] = (acc[date] || 0) + 1;
            return acc;
        }, {});

        // Step 2: Generate the current week range (7 days ending today)
        const weekRange = generateCurrentWeekRange();

        // Step 3: Fill in missing days with zero counts, only for the current week
        return weekRange.map((date) => ({
            date,
            count: bins[date] || 0,
        }));
    }, [data]);

    return (
        <Box sx={{ padding: 3 }}>
            <Typography variant='h6' gutterBottom>
                Procedures Binned by Day (Current Fortnight)
            </Typography>
            <ResponsiveContainer width='100%' height={400}>
                <LineChart data={binnedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date' />
                    <YAxis />
                    <Tooltip />
                    <Line type='monotone' dataKey='count' stroke='#3f51b5' strokeWidth={2} />
                </LineChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default ProcedurePlot;
