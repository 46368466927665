import { Button, Stack } from '@mui/material';
import { withAsyncBoundary } from '../../../aceapi/utils';
import {
    useAssessmentsAnnotationCategoriesList,
    useAssessmentsAssessmentFieldsList,
    useAssessmentsCasesGetAssessmentAnswers,
    useAssessmentsCasesGetAssessmentTypes,
    useAssessmentsCasesRead,
} from '../../../aceapi/aceComponents';
import { FormContainer } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form';
import React, { useCallback, useMemo, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import AssessmentCommonFields from '../common/AssessmentCommonFields';
import PreAssessmentTypeSelect from './PreAssessmentTypeSelect';
import useCaseAssessmentMgr from '../hooks/useCaseAssessmentMgr';
import PreAssessmentAnnotationsSelect from './PreAssessmentAnnotationsSelect';
import Typography from '@mui/material/Typography';

const PreAssessmentForm = forwardRef((props, ref) => {
    const { id } = useParams();
    const { handleNext } = props;

    const { data: currentCase } = useAssessmentsCasesRead({ pathParams: { id } });
    const { data: caseTypes } = useAssessmentsCasesGetAssessmentTypes({});
    const { data: annotationCategories } = useAssessmentsAnnotationCategoriesList({});

    const filteredCategories = useMemo(() => annotationCategories?.filter((c) => !c.hidden), [annotationCategories]);

    const { updateCase, updateAssessmentAnswers } = useCaseAssessmentMgr();

    const { data: fields } = useAssessmentsAssessmentFieldsList({
        queryParams: { stage: 'PRE' },
    });
    const { data: answers } = useAssessmentsCasesGetAssessmentAnswers({
        pathParams: { id },
        queryParams: { stage: 'PRE' },
    });

    const commonFieldsDefaultValues = useMemo(
        () => Object.fromEntries(answers?.map(({ field_key, value }) => [field_key, value]) ?? []),
        [answers],
    );

    const annDefaultValues = useMemo(
        () =>
            Object.fromEntries(
                filteredCategories?.map(({ id }) => [
                    `ann_${id}`,
                    currentCase.selected_annotations?.map((ann) => ann.id)?.includes(id),
                ]),
            ),
        [filteredCategories, currentCase.selected_annotations],
    );

    const formContext = useForm({
        defaultValues: {
            case_type: currentCase.type,
            ...commonFieldsDefaultValues,
            ...annDefaultValues,
        },
    });

    const {
        handleSubmit,
        formState: { isDirty },
    } = formContext;

    const onSubmit = useCallback(
        async (data) => {
            if (!isDirty) return;

            const annotations = Object.entries(data)
                .filter(([key]) => key.startsWith('ann_'))
                .map(([key, value]) => ({
                    category: parseInt(key.replace('ann_', '')),
                    value,
                }))
                .filter(({ value }) => value === true)
                .map(({ category }) => category);
            const otherData = Object.fromEntries(Object.entries(data).filter(([key]) => !key.startsWith('ann_')));
            const { case_type, ...answers } = otherData;
            if (case_type !== currentCase.type) {
                await updateCase(id, { type: case_type });
            }
            await updateAssessmentAnswers(id, answers);
            await updateCase(id, { selected_annotations_ids: annotations });
        },
        [isDirty, currentCase.type, updateAssessmentAnswers, id, updateCase],
    );

    const debouncedSubmit = useMemo(() => debounce(onSubmit, 500), [onSubmit]);

    useImperativeHandle(ref, () => ({
        submit: () => handleSubmit(onSubmit)(),
    }));

    return (
        <Stack spacing={2}>
            <FormContainer formContext={formContext}>
                <Stack spacing={2} p={1}>
                    <PreAssessmentTypeSelect caseTypes={caseTypes} onChange={handleSubmit(onSubmit)} />
                    <AssessmentCommonFields fields={fields} onChange={handleSubmit(debouncedSubmit)} />
                    <PreAssessmentAnnotationsSelect categories={filteredCategories} />
                </Stack>
            </FormContainer>
            <Stack spacing={2} direction='row' alignItems='center'>
                <Button
                    variant='contained'
                    onClick={() => handleSubmit(onSubmit)().then(handleNext)}
                    disabled={currentCase.type === 0}
                >
                    Next
                </Button>
                {currentCase.type === 0 && (
                    <Typography variant='body2' color='error'>
                        You must select a case type to continue
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
});

PreAssessmentForm.displayName = 'PreAssessmentForm';

export default withAsyncBoundary(PreAssessmentForm);
