import { PROCEDURE_STATUS, WORKFLOW_MODES } from '../components/summary/hooks/useSummaryInputs';

const searchFields = [
    {
        label: 'Patient ID',
        name: 'patient_id',
        type: 'text',
    },
    {
        label: 'Username',
        name: 'username',
        type: 'text',
    },
    {
        label: 'Status',
        name: 'status',
        type: 'select',
        options: [{ id: '', label: 'All' }, ...PROCEDURE_STATUS.map((l) => ({ id: l, label: l }))],
    },
    {
        label: 'Mode',
        name: 'mode',
        type: 'select',
        options: [{ id: '', label: 'All' }, ...WORKFLOW_MODES.map((l) => ({ id: l, label: l }))],
    },
    {
        label: 'From',
        name: 'from',
        type: 'date',
    },
    {
        label: 'To',
        name: 'to',
        type: 'date',
    },
    {
        label: 'Hide blank Patient IDs',
        name: 'hide_blank_patient_ids',
        type: 'switch',
        defaultValue: false,
    },
];

export default searchFields;
