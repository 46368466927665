import { useAceApp } from '../../components/Menu/ReportAppSelector';
import { useStreamingUserList, useStreamingUserRead, useGroupMetagroupsList } from '../aceComponents';

export default function useInstall() {
    const { app } = useAceApp();
    const { data: streamingUserList } = useStreamingUserList(
        {
            queryParams: {
                app,
            },
        },
        { useErrorBoundary: false, retry: false },
    );

    const useStreamingUser = (user) => {
        const { data: streamingUser } = useStreamingUserRead(
            {
                pathParams: { username: user },
                queryParams: {
                    app,
                },
            },
            { useErrorBoundary: false, retry: false },
        );
        return streamingUser;
    };
    const { data: groupMetaData } = useGroupMetagroupsList({});

    return {
        streamingUserList,
        useStreamingUser,
        groupMetaData,
    };
}
