import { useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Stack, Tab } from '@mui/material';
import Box from '@mui/material/Box';
import { useGroupTypesList } from '../../aceapi/aceComponents';
import { useFormContext } from 'react-hook-form-mui';
import { StatOptionForm } from './StatOptionForm';
import SummaryStat from './stats/SummaryStat';

export default function TabPerTypes({ name }) {
    const { watch, setValue: setFormValue } = useFormContext();
    const [value, setValue] = useState(() => watch('groupType') ?? 'user');
    const { data: types } = useGroupTypesList({});

    const handleTabChange = (v) => {
        setFormValue('groupType', v);
        setValue(v);
    };

    const params = watch();

    return (
        <Stack spacing={2}>
            <Box pl='10%' pr='10%'>
                <StatOptionForm statName={name} />
            </Box>
            <TabContext value={value}>
                <TabList onChange={(e, v) => handleTabChange(v)} centered>
                    <Tab value='user' label='user' />
                    {types
                        .filter((type) => type.name !== 'meta')
                        .map((type) => (
                            <Tab key={type.id} value={type.name} label={type.name} />
                        ))}
                </TabList>
                <TabPanel value='user'>
                    <SummaryStat name={name} type='user' params={params} />
                </TabPanel>
                {types.map((type) => (
                    <TabPanel key={type.id} value={type.name}>
                        <SummaryStat name={name} type={type.name} params={params} />
                    </TabPanel>
                ))}
            </TabContext>
        </Stack>
    );
}
