import useShow from '../../aceapi/hooks/useShow';
import Forbidden from '../../components/Login/Forbidden';
import PaperCard from '../../components/report/PaperCard';
import MACaseSeriesMetricsMain from './case_series/MACaseSeriesMetricsMain';

export default function MAPortal(props) {
    const show = useShow();

    return !show.ma_portal ? (
        <Forbidden />
    ) : (
        <PaperCard title='MedAffairs Portal' xs={12} variant='h3' align='center'>
            <MACaseSeriesMetricsMain {...props} />
        </PaperCard>
    );
}
