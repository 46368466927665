import useCaseAnnotationMgr from './hooks/useCaseAnnotationMgr';
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import ShapeLineIcon from '@mui/icons-material/ShapeLine';
import { useProceduresRead } from '../../aceapi/aceComponents';
import { useParams } from 'react-router-dom';
import { useAceApp } from '../Menu/ReportAppSelector';
import Typography from '@mui/material/Typography';
import useShow from '../../aceapi/hooks/useShow';

export default function CaseAnnotationCreate() {
    const show = useShow();
    const { createCase } = useCaseAnnotationMgr();

    const { uuid } = useParams();
    const { app } = useAceApp();
    const { data: procedure } = useProceduresRead({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });

    return (
        <Stack spacing={2}>
            <Typography variant='h5' align='center'>
                Procedure &quot;{procedure.patient_id ?? procedure.procedure_id}&quot; ({procedure.username}) have not
                yet been annotated.
            </Typography>
            <Box display='flex' justifyContent='center' p={0}>
                <Button
                    variant='contained'
                    size='large'
                    color='primary'
                    onClick={() => createCase(uuid)}
                    startIcon={<ShapeLineIcon />}
                    disabled={show.case_annotations_external_restriction}
                >
                    Start Annotating
                </Button>
            </Box>
            {show.case_annotations_external_restriction && (
                <Typography variant='caption' color='error' align='center'>
                    You do not have permission to create new annotations. If you believe there should be an annotation
                    here, please reach out.
                </Typography>
            )}
        </Stack>
    );
}
