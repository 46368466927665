import React from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Paper, Typography, Box } from '@mui/material';

export default function NetworkStatisticsTable(props) {
    const { data } = props;

    return (
        <Box sx={{ padding: 1 }}>
            <Typography variant='h5' gutterBottom>
                Network Statistics Tests
            </Typography>

            <Paper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Timestamp</TableCell>
                            <TableCell>Loaded Latency (ms)</TableCell>
                            <TableCell>Loaded Jitter (ms)</TableCell>
                            <TableCell>Unloaded Latency (ms)</TableCell>
                            <TableCell>Unloaded Jitter (ms)</TableCell>
                            <TableCell>Upload (kbps)</TableCell>
                            <TableCell>Upload StdDev (kbps)</TableCell>
                            <TableCell>Download (kbps)</TableCell>
                            <TableCell>Download StdDev (kbps)</TableCell>
                            <TableCell>% Requests Completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => (
                            <TableRow key={item.id}>
                                <TableCell>{item.id}</TableCell>
                                <TableCell>{new Date(item.timestamp).toLocaleString()}</TableCell>
                                <TableCell>{item.loaded_latency.toFixed(1)}</TableCell>
                                <TableCell>{item.loaded_jitter.toFixed(1)}</TableCell>
                                <TableCell>{item.unloaded_latency.toFixed(1)}</TableCell>
                                <TableCell>{item.unloaded_jitter.toFixed(1)}</TableCell>
                                <TableCell>{item.throughput_upload.toFixed(1)}</TableCell>
                                <TableCell>{item.throughput_upload_stddev.toFixed(1)}</TableCell>
                                <TableCell>{item.throughput_download.toFixed(1)}</TableCell>
                                <TableCell>{item.throughput_download_stddev.toFixed(1)}</TableCell>
                                <TableCell>{item.percentage_requests_completed}%</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Paper>
        </Box>
    );
}
