import { Skeleton, Typography } from '@mui/material';
import useProcTimeDetails from '../../aceapi/hooks/useProcTimeDetails';
import { AsyncBoundary } from '../../aceapi/utils';
import PaperCard from './PaperCard';

function AsyncCaecumCard() {
    const { caecumTimestamp, grossWithdrawalTime } = useProcTimeDetails();
    return (
        <>
            <Typography align='left'>
                {caecumTimestamp ? `Reached at ${new Date(caecumTimestamp).toLocaleTimeString()}` : 'Not reached'}
            </Typography>
            {grossWithdrawalTime && <Typography align='left'>Withdrawal time: {grossWithdrawalTime}</Typography>}
        </>
    );
}

export default function CaecumCard() {
    return (
        <PaperCard title='Caecum'>
            <AsyncBoundary
                fallback={
                    <Typography variant='h5'>
                        <Skeleton animation='wave' />
                    </Typography>
                }
            >
                <AsyncCaecumCard />
            </AsyncBoundary>
        </PaperCard>
    );
}
