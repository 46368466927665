import { withAsyncBoundary } from '../../aceapi/utils';
import { useNavigate, useParams } from 'react-router-dom';
import {
    useAnnotationsCasesByProcedureId,
    useAnnotationsCasesGetAnnotationValues,
    useAnnotationsCasesProjects,
    useAnnotationsFieldsList,
    useAssessmentsCasesByProcedureId,
    useProceduresComments,
} from '../../aceapi/aceComponents';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from 'lodash';
import { Button, Stack, Typography } from '@mui/material';
import { FormContainer } from 'react-hook-form-mui';
import AnnotationCommonFields from './common/AnnotationCommonFields';
import useCaseAnnotationMgr from './hooks/useCaseAnnotationMgr';
import Grid from '@mui/material/Grid';
import VideoOrStream from '../stream/VideoOrStream';
import useVideoSeeker from '../stream/useVideoSeeker';
import useProcOngoing from '../../aceapi/hooks/useProcOngoing';
import VideoNavButtons from '../stream/VideoNavButtons';
import ReportTimeline from '../Charts/ReportTimeline';
import useVideoKeyNav from '../shortcuts/useVideoKeyNav';
import useFrames from '../report/useFrames';
import useAuthorized from '../../aceapi/hooks/useAuthorized';
import useExtraData from '../report/useExtraData';
import useShow from '../../aceapi/hooks/useShow';
import AnnotationTable from './table/AnnotationTable';
import AnnotationAddNewRow from './table/AnnotationAddNewRow';
import Box from '@mui/material/Box';
import LiveAssessmentList from '../assessment/live/LiveAssessmentList';
import YAML from 'yaml';
import { useAceApp } from '../Menu/ReportAppSelector';
import useProcTime from '../../aceapi/hooks/useProcTime';
import format from 'format-duration';
import ShortcutCard from '../shortcuts/ShortcutCard';

function CaseAnnotationForm() {
    const { uuid } = useParams();
    const show = useShow();
    const { data: assessment } = useAssessmentsCasesByProcedureId(
        { queryParams: { procedureId: uuid } },
        { enabled: show.case_assessments },
    );
    const { data: caseData } = useAnnotationsCasesByProcedureId({ queryParams: { procedureId: uuid } });
    const { data: projectData } = useAnnotationsCasesProjects({ pathParams: { id: caseData?.id } });

    const selectedCategories = useMemo(() => {
        const assessmentCategories = assessment?.selected_annotations?.map((ac) => ac.id) ?? [];
        const projectCategories = projectData?.flatMap((p) => p.categories)?.map((c) => c.id) ?? [];
        return [...new Set([...assessmentCategories, ...projectCategories])];
    }, [assessment, projectData]);

    const { updateAnnotationValues } = useCaseAnnotationMgr();

    const { data: fields } = useAnnotationsFieldsList({
        queryParams: { globalField: true, ...(selectedCategories && { categoriesFilter: selectedCategories }) },
    });
    const { data: values } = useAnnotationsCasesGetAnnotationValues({
        pathParams: { id: caseData.id },
    });

    const commonFieldsDefaultValues = useMemo(
        () =>
            values?.reduce((acc, { id, field_slug, value }) => {
                const global = fields.find((field) => field.slug === field_slug);
                if (global) {
                    acc[field_slug] = value;
                } else {
                    if (!acc[field_slug]) {
                        acc[field_slug] = {};
                    }
                    acc[field_slug][id] = value;
                }
                return acc;
            }, {}) ?? {},
        [fields, values],
    );

    const formContext = useForm({
        defaultValues: {
            ...commonFieldsDefaultValues,
        },
    });

    const { handleSubmit } = formContext;
    const onSubmit = useCallback(
        async (data) => {
            await updateAnnotationValues(caseData.id, data);
        },
        [caseData.id, updateAnnotationValues],
    );

    const debouncedSubmit = useMemo(() => debounce(onSubmit, 500), [onSubmit]);

    const authorized = useAuthorized();
    const seeker = useVideoSeeker();
    const { streaming } = useProcOngoing();
    const noop = useCallback(() => null, []);
    const { numberOfDetections } = useVideoKeyNav(seeker);
    const { extraRow } = useExtraData({
        show: show.extra_models,
        authorized,
    });
    const frameProps = useFrames({
        uuid,
        setUnavailable: noop,
        authorized,
    });

    const { app } = useAceApp();
    const { data: comments } = useProceduresComments(
        { pathParams: { procedureId: uuid }, queryParams: { app } },
        { enabled: show.comments },
    );
    const liveComments = useMemo(
        () =>
            comments
                ?.filter((comment) => comment.name === 'Live Reporting')
                .map((comment) => ({ ...comment, ...YAML.parse(comment.body) })),
        [comments],
    );

    const navigate = useNavigate();

    const time = useProcTime(app, uuid);
    const valuesBars = useMemo(() => {
        return values
            .filter((value) => value.timestamp > 0)
            .map((value) => ({
                label: value.field_slug,
                data: [(value.timestamp - time.start) / (time.end - time.start)],
                color: `hsl(${value.field_slug.split('').reduce((acc, char) => char.charCodeAt(0) + acc, 0) % 360}, 100%, 50%)`,
                showLabel: true,
            }));
    }, [time.end, time.start, values]);

    return (
        <Stack spacing={2}>
            {!assessment?.id && !projectData?.length && (
                <Typography variant='h6' align='center' color='error'>
                    ⚠️ THIS PROCEDURE HAS NOT BEEN ASSESSED NOR IS IT PART OF A PROJECT ⚠️
                </Typography>
            )}
            <Grid container spacing={2} sx={{ width: '100%' }}>
                <Grid item xs={6}>
                    <VideoOrStream seeker={seeker} streaming={streaming} setUnavailable={noop} />
                    <VideoNavButtons seeker={seeker} />
                    <Typography variant='body2'>Number of Detections: {numberOfDetections}</Typography>
                    {show.timeline && (
                        <ReportTimeline
                            setUnavailable={noop}
                            loaded={noop}
                            toggleBars
                            seeker={seeker}
                            extraRow={extraRow}
                            extraBars={valuesBars}
                            compact
                            showFreezeFrames
                            debugCaecum
                            {...frameProps}
                        />
                    )}
                    <Typography variant='h6' color='primary' fontFamily='Roboto Mono, Monospace'>
                        Cursor: {seeker.frameInput()} (video: {format(seeker.timestamp.state - seeker.video_start)} |
                        timeline: {format(seeker.timestamp.state - time.start)})
                    </Typography>
                    <Box p={1}>
                        {assessment?.id && (
                            <LiveAssessmentList seeker={seeker} comments={liveComments} noSelect noInsert noDelete />
                        )}
                    </Box>
                    <Box p={1}>
                        <ShortcutCard xs={12} showAnnotation={false} showDetection={show.cad} />
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <FormContainer formContext={formContext}>
                        <Stack spacing={2}>
                            <Box>
                                <AnnotationCommonFields
                                    fields={fields}
                                    onChange={handleSubmit(debouncedSubmit)}
                                    xs={12}
                                />
                            </Box>
                            {!show.case_annotations_external_restriction && (
                                <AnnotationAddNewRow
                                    timestamp={seeker.timestamp.state}
                                    selectedCategories={selectedCategories}
                                />
                            )}
                            <AnnotationTable
                                onChange={handleSubmit(debouncedSubmit)}
                                seeker={seeker}
                                selectedCategories={selectedCategories}
                            />
                            <Stack spacing={2} direction='row' alignItems='center'>
                                <Button variant='contained' onClick={() => handleSubmit(onSubmit)()}>
                                    Save
                                </Button>
                                {assessment?.id && (
                                    <Button
                                        variant='outlined'
                                        color='secondary'
                                        onClick={() => navigate(`/assessments/${uuid}`)}
                                    >
                                        Go to Assessment
                                    </Button>
                                )}
                            </Stack>
                        </Stack>
                    </FormContainer>
                </Grid>
            </Grid>
        </Stack>
    );
}

export default withAsyncBoundary(CaseAnnotationForm);
