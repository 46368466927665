import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Divider, Grid, TextField, Typography } from '@mui/material';
import useCommentHelper from '../../report/useCommentHelper';
import { debounce } from 'lodash';
import YAML from 'yaml';
import { useParams } from 'react-router-dom';
import { useAssessmentsCasesRead } from '../../../aceapi/aceComponents';
import LiveAssessmentAnnotation from './LiveAssessmentAnnotation';
import TocIcon from '@mui/icons-material/Toc';

export const getAnswerChainText = (answer, includeComment = false, includeIds = false) => {
    if (!answer) {
        return '';
    }

    function getText(answer) {
        let text = answer.text;
        if (includeComment && answer.comment) {
            text += ` (${answer.comment})`;
        }
        if (includeIds && answer.annotation) {
            const ids = Object.values(answer.annotation)
                .flatMap((row) => Object.entries(row))
                .filter(([key]) => key.toLowerCase().endsWith('-id'))
                .map(([key, value]) => `${key}=${value}`);
            if (ids.length > 0) {
                text += ` [${ids.join(', ')}]`;
            }
        }
        return text;
    }

    if (answer.answer) {
        return `${getText(answer)} > ${getAnswerChainText(answer.answer)}`;
    }

    return getText(answer);
};

function LiveAssessmentAnswer({ commentId, actions, answer, onAnswerChange, isSaving }) {
    const { id } = useParams();
    const { data: caseData } = useAssessmentsCasesRead({ pathParams: { id } });
    const caseAnnotations = useMemo(
        () => caseData?.selected_annotations.map((category) => category.id),
        [caseData?.selected_annotations],
    );
    const filteredActions = useMemo(
        () =>
            actions?.filter(
                (action) =>
                    action.categories.length === 0 ||
                    action.categories.map((category) => category.id).some((id) => caseAnnotations.includes(id)),
            ),
        [actions, caseAnnotations],
    );

    const [selectedActionKey, setSelectedActionKey] = useState(answer?.key || '');
    const [comment, setComment] = useState(answer?.comment || '');

    useEffect(() => {
        setSelectedActionKey(answer?.key || '');
        setComment(answer?.comment || '');
    }, [answer?.key, answer?.comment]);

    const selectedAction = filteredActions.find((action) => action.key === selectedActionKey);
    const selectedSubActions = selectedAction?.children;

    const handleSelection = (action) => {
        /* Ask for confirmation if the user is about to overwrite an existing action that has either:
        - a non-empty comment
        - annotations
        - nested answers
         */
        if (selectedAction && (answer.comment || answer.annotation || answer.answer)) {
            console.log(selectedAction.comment, selectedAction.annotations);
            if (
                !window.confirm(
                    'Are you sure you want to overwrite the existing action? Comments and annotations will be lost!',
                )
            ) {
                return;
            }
        }

        setSelectedActionKey(action.key);
        setComment('');
        const newAnswer = {
            key: action.key,
            text: action.display_text,
            // Initialize nested answer if sub-actions exist
            ...(Array.isArray(action.children) && action.children.length > 0 ? { answer: {} } : {}),
        };
        onAnswerChange(newAnswer);
    };

    const handleCommentChange = (event) => {
        const newComment = event.target.value;
        setComment(newComment);
        onAnswerChange({
            ...answer,
            comment: newComment,
        });
    };

    const handleCommentBlur = () => {
        // Trigger save when the comment field loses focus
        onAnswerChange({
            ...answer,
            comment: comment,
        });
    };

    const handleCommentKeyDown = (event) => {
        if (event.key === 'Enter') {
            // Prevent default behavior (e.g., adding a newline)
            event.preventDefault();
            // Trigger save when Enter key is pressed
            onAnswerChange({
                ...answer,
                comment: comment,
            });
            // Optionally remove focus from the comment field
            event.target.blur();
        }
    };

    const handleSubAnswerChange = (subAnswer) => {
        onAnswerChange({
            ...answer,
            answer: subAnswer,
        });
    };

    const handleAnnotationChange = (row_slug, field_slug, value) => {
        onAnswerChange({
            ...answer,
            annotation: {
                ...answer.annotation,
                [row_slug]: {
                    ...answer.annotation?.[row_slug],
                    [field_slug]: value,
                },
            },
        });
    };

    return (
        <>
            {!selectedAction && selectedActionKey && selectedActionKey !== 'unknown' && (
                <Grid item xs={12}>
                    <Typography variant='body2' color='error'>
                        OLD ANNOTATION NO LONGER AVAILABLE: <b>{getAnswerChainText(answer)}</b>. Replace with a new
                        annotation or add it back to the form!
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} container spacing={2}>
                {filteredActions.map((action) => (
                    <Grid item key={action.key} xs={3}>
                        <Button
                            variant='contained'
                            color={selectedActionKey === action.key ? 'primary' : 'secondary'}
                            fullWidth
                            sx={{ minHeight: '4rem' }}
                            onClick={() => handleSelection(action)}
                            startIcon={action?.annotation_include && <TocIcon />}
                            // Remove disabled={isSaving} to keep the form interactive
                        >
                            {action.display_text}
                        </Button>
                    </Grid>
                ))}
            </Grid>
            {/* Display annotation fields if the action contains annotations */}
            {Array.isArray(selectedAction?.annotations) && selectedAction?.annotations.length > 0 && (
                <Grid item xs={12}>
                    <LiveAssessmentAnnotation
                        key={commentId}
                        annotationFields={selectedAction.annotations}
                        annotationValues={
                            answer?.annotation ??
                            Object.fromEntries(
                                selectedAction.annotations.map((field) => [
                                    field.slug,
                                    Object.fromEntries(field.row_fields.map((subField) => [subField.slug, ''])),
                                    // does not solve the issue of partially filled annotations
                                ]),
                            )
                        }
                        onAnnotationChange={handleAnnotationChange}
                    />
                </Grid>
            )}

            {/* Display comment field if allow_comment is true */}
            {selectedAction?.allow_comment && (
                <Grid item xs={12}>
                    <TextField
                        label='Comment'
                        value={comment}
                        onChange={handleCommentChange}
                        onBlur={handleCommentBlur}
                        onKeyDown={handleCommentKeyDown}
                        fullWidth
                    />
                </Grid>
            )}

            {Array.isArray(selectedSubActions) && selectedActionKey && (
                <>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <LiveAssessmentAnswer
                        commentId={commentId}
                        actions={selectedSubActions}
                        answer={answer?.answer || {}}
                        onAnswerChange={handleSubAnswerChange}
                        isSaving={isSaving}
                    />
                </>
            )}
        </>
    );
}

export default function LiveAssessmentForm({ form, selectedComment }) {
    const { editComment } = useCommentHelper();

    const [answer, setAnswer] = useState(selectedComment?.answer || {});
    const [isSaving, setIsSaving] = useState(false);

    const saveCountRef = useRef(0);
    const isAnswerDirty = useRef(false);

    useEffect(() => {
        setAnswer(selectedComment?.answer || {});
        isAnswerDirty.current = false;
    }, [selectedComment]);

    const debouncedSave = useMemo(
        () =>
            debounce(async (answerToSave, saveCount) => {
                if (selectedComment?.id && answerToSave) {
                    try {
                        await editComment(
                            selectedComment.id,
                            YAML.stringify({
                                form: form.name,
                                answer: answerToSave,
                            }),
                        );
                    } finally {
                        if (saveCount === saveCountRef.current) {
                            setIsSaving(false);
                            isAnswerDirty.current = false;
                        }
                    }
                }
            }, 250),
        [selectedComment.id, editComment, form.name],
    );

    useEffect(() => {
        if (isAnswerDirty.current) {
            setIsSaving(true);
            saveCountRef.current += 1;
            const currentSaveCount = saveCountRef.current;
            debouncedSave(answer, currentSaveCount);
        }

        return () => {
            debouncedSave.cancel();
        };
    }, [answer, debouncedSave]);

    const handleAnswerChange = (newAnswer) => {
        isAnswerDirty.current = true;
        setAnswer(newAnswer);
    };

    return (
        <Grid item xs={12} container spacing={4}>
            <LiveAssessmentAnswer
                commentId={selectedComment?.id}
                actions={form.actions}
                answer={answer}
                onAnswerChange={handleAnswerChange}
                isSaving={isSaving}
            />
            <Grid item xs={12}>
                {isSaving ? (
                    <Typography variant='body2'>Saving...</Typography>
                ) : (
                    <Typography variant='body2'>All changes saved.</Typography>
                )}
            </Grid>
        </Grid>
    );
}
