import { withAsyncBoundary } from '../../aceapi/utils';
import { Stack } from '@mui/material';
import CaseAssessmentList from './CaseAssessmentList';
import Typography from '@mui/material/Typography';
import CaseAssessmentNew from './CaseAssessmentNew';

function CaseAssessmentMain() {
    return (
        <Stack spacing={2}>
            <CaseAssessmentNew />
            <Typography variant='h6'>Previous Cases:</Typography>
            <CaseAssessmentList />
        </Stack>
    );
}

export default withAsyncBoundary(CaseAssessmentMain);
