import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import useExtraData from '../../components/report/useExtraData';
import { useProceduresCaqs, useVideoRead } from '../aceComponents';
import useAuthorized from './useAuthorized';
import useProcTime from './useProcTime';
import useShow from './useShow';

export default function useProcTimeDetails(overrideApp = null, overrideUuid = null) {
    const ace = useAceApp();
    const params = useParams();
    const app = overrideApp ?? ace.app;
    const uuid = overrideUuid ?? params.uuid;
    const authorized = useAuthorized(overrideApp, overrideUuid);
    const show = useShow();

    const time = useProcTime();
    const { extraRow } = useExtraData({ show: show.extra_models });

    const { data: caqData } = useProceduresCaqs({ pathParams: { procedureId: uuid } });
    const bodyEnterTimestamp = caqData?.procedure_out_of_body_start_timestamp;
    const bodyExitTimestamp = caqData?.procedure_out_of_body_end_timestamp;
    const caecumTimestamp = caqData?.caecum_timestamp;
    const retroflexionTimestamp = caqData?.retroflexion_timestamp;

    const bodyExitTimestampOrNow = bodyExitTimestamp ?? time.internalEnd;

    const caecumTimeRelativeToProcedureStart = useMemo(
        () => caecumTimestamp && caecumTimestamp - time.start,
        [caecumTimestamp, time.start],
    );

    const insertionTime = useMemo(
        () => bodyEnterTimestamp && (caecumTimestamp ?? time.internalEnd) - bodyEnterTimestamp,
        [bodyEnterTimestamp, caecumTimestamp, time.internalEnd],
    );

    const cleaningTime = useMemo(
        () =>
            caecumTimeRelativeToProcedureStart &&
            extraRow &&
            (extraRow
                .filter((x) => x.val.toLowerCase().includes('cleaning'))
                .map((x) => Math.max(x.end - Math.max(x.start, caecumTimeRelativeToProcedureStart), 0))
                .reduce((a, b) => a + b, 0) ??
                0),
        [extraRow, caecumTimeRelativeToProcedureStart],
    );

    const interventionTime = useMemo(
        () =>
            caecumTimeRelativeToProcedureStart &&
            extraRow &&
            extraRow
                .filter((x) => /(therap|forcep|biopsy)/.test(x.val.toLowerCase()))
                .map((x) => Math.max(x.end - Math.max(x.start, caecumTimeRelativeToProcedureStart), 0))
                .reduce((a, b) => a + b, 0),
        [extraRow, caecumTimeRelativeToProcedureStart],
    );
    const { data: video } = useVideoRead(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: authorized },
    );

    const grossWithdrawalTime = useMemo(
        () => caecumTimestamp && bodyExitTimestampOrNow && bodyExitTimestampOrNow - caecumTimestamp,
        [caecumTimestamp, bodyExitTimestampOrNow],
    );
    const netWithdrawalTime = useMemo(
        () =>
            grossWithdrawalTime &&
            cleaningTime &&
            interventionTime &&
            grossWithdrawalTime - cleaningTime - interventionTime,
        [grossWithdrawalTime, cleaningTime, interventionTime],
    );

    const totalEndoscopyTime = useMemo(
        () => bodyEnterTimestamp && bodyExitTimestampOrNow - bodyEnterTimestamp,
        [bodyEnterTimestamp, bodyExitTimestampOrNow],
    );

    const totalTime = useMemo(
        () => (video?.video?.end ?? time.end) - (video?.video?.start ?? time.start),
        [time.end, time.start, video?.video?.end, video?.video?.start],
    );

    return useMemo(
        () => ({
            bodyEnterTimestamp,
            bodyExitTimestamp,
            bodyExitTimestampOrNow,
            caecumTimestamp,
            caecumTimeRelativeToProcedureStart,
            cleaningTime,
            insertionTime,
            interventionTime,
            grossWithdrawalTime,
            netWithdrawalTime,
            retroflexionTimestamp,
            totalTime,
            totalEndoscopyTime,
        }),
        [
            bodyEnterTimestamp,
            bodyExitTimestamp,
            bodyExitTimestampOrNow,
            caecumTimestamp,
            caecumTimeRelativeToProcedureStart,
            cleaningTime,
            insertionTime,
            interventionTime,
            grossWithdrawalTime,
            netWithdrawalTime,
            retroflexionTimestamp,
            totalTime,
            totalEndoscopyTime,
        ],
    );
}
