import { withAsyncBoundary } from '../../../aceapi/utils';
import {
    useAnnotationsCasesList,
    useAssessmentsCasesGetAssessmentTypes,
    useAssessmentsCasesIssues,
    useAssessmentsCasesList,
} from '../../../aceapi/aceComponents';
import { timedelta } from '../../../components/summary/utils';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useMemo } from 'react';
import Typography from '@mui/material/Typography';

function MACaseSeriesOverviewStats({ csId }) {
    const { data: assessments } = useAssessmentsCasesList({});
    const { data: annotations } = useAnnotationsCasesList({});
    const { data: issues } = useAssessmentsCasesIssues({ queryParams: { caseSeries: csId } });

    const assessmentData = useMemo(
        () =>
            assessments
                .filter((assessment) => assessment.already_completed)
                .map((assessment) => {
                    const annotation = annotations.find(
                        (annotation) => annotation.procedure_id === assessment.procedure_id,
                    );
                    const issue = issues.find((issue) => issue.procedure_id === assessment.procedure_id);
                    return { ...assessment, annotation, issue_count: issue?.issue_count ?? 0 };
                }),
        [assessments, annotations, issues],
    );

    const { data: allTypes } = useAssessmentsCasesGetAssessmentTypes({});
    const caseTypes = allTypes.filter((caseType) => caseType.label.toLowerCase() !== 'unanswered');

    const filteredAssessments = assessmentData.filter((assessment) => assessment.case_series === csId);
    const lastWeek = Date.now() - timedelta.WEEK;
    const recentAssessments = filteredAssessments.filter((assessment) => new Date(assessment.created_at) > lastWeek);

    return (
        <Stack>
            <Typography variant='h5'>Overview Metrics</Typography>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Case Series Metrics</TableCell>
                        {caseTypes.map((caseType) => (
                            <TableCell key={caseType.id}>{caseType.label}</TableCell>
                        ))}
                        <TableCell>Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Total Assessments</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = filteredAssessments.filter(
                                (assessment) => assessment.type === caseType.id,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>{filteredAssessments.length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Total with Annotations</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = filteredAssessments.filter(
                                (assessment) => assessment.type === caseType.id && assessment.annotation,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>
                            {filteredAssessments.filter((assessment) => assessment.annotation).length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Recent Assessments (since last week)</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = recentAssessments.filter(
                                (assessment) => assessment.type === caseType.id,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>{recentAssessments.length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Recent with Annotations (since last week)</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = recentAssessments.filter(
                                (assessment) => assessment.type === caseType.id && assessment.annotation,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>{recentAssessments.filter((assessment) => assessment.annotation).length}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Missing Annotations</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = filteredAssessments.filter(
                                (assessment) => assessment.type === caseType.id && !assessment.annotation,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>
                            {filteredAssessments.filter((assessment) => !assessment.annotation).length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Assessments with at least one issue</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = filteredAssessments.filter(
                                (assessment) => assessment.type === caseType.id && assessment.issue_count > 0,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>
                            {filteredAssessments.filter((assessment) => assessment.issue_count > 0).length}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Recent Assessments with at least one issue (since last week)</TableCell>
                        {caseTypes.map((caseType) => {
                            const count = recentAssessments.filter(
                                (assessment) => assessment.type === caseType.id && assessment.issue_count > 0,
                            ).length;
                            return <TableCell key={caseType.id}>{count}</TableCell>;
                        })}
                        <TableCell>
                            {recentAssessments.filter((assessment) => assessment.issue_count > 0).length}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </Stack>
    );
}

export default withAsyncBoundary(MACaseSeriesOverviewStats);
