import React, { useCallback, memo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useInstall from '../../aceapi/hooks/useInstall';
import {
    Box,
    Card,
    CardContent,
    Typography,
    List,
    ListItem,
    ListItemText,
    Grid,
    Button,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SmartListItemText from './SmartListItem';
import ProcedurePlot from './ProcedurePlot';
import NetworkStatisticsTable from './NetworkStatisticsChart';
import FinalFormChart from './FormChart';

/**
 * Recursive function to render a List or Accordion for nested data.
 * Moved outside the component so it's not recreated on each render.
 */
function renderNestedList(data, isNested = false) {
    if (typeof data !== 'object' || data === null) {
        return (
            <ListItem>
                <ListItemText primary={String(data)} />
            </ListItem>
        );
    }

    if (Array.isArray(data)) {
        return data.length > 0 ? (
            data.map((item, index) => (
                <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography>{`Item ${index + 1}`}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <List disablePadding>{renderNestedList(item, true)}</List>
                    </AccordionDetails>
                </Accordion>
            ))
        ) : (
            <ListItem>
                <ListItemText primary='No items' />
            </ListItem>
        );
    }

    return Object.entries(data).map(([key, value], index) => {
        const isLeafNode = typeof value !== 'object' || value === null;
        return isLeafNode ? (
            <ListItem key={`${key}-${index}`} style={{ paddingLeft: isNested ? 16 : 0 }}>
                <SmartListItemText k={key} value={value} />
            </ListItem>
        ) : (
            <Accordion key={`${key}-${index}`}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{key}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <List disablePadding>{renderNestedList(value, true)}</List>
                </AccordionDetails>
            </Accordion>
        );
    });
}

/**
 * A memoized child component that displays all details about a streamingUser.
 */
const DetailedInformation = memo(function DetailedInformation({ streamingUser, onGoBack }) {
    if (!streamingUser) {
        return null;
    }

    return (
        <Card>
            <CardContent>
                <Box display='flex' justifyContent='space-between' alignItems='center'>
                    <Typography variant='h6' gutterBottom>
                        Detailed Information
                    </Typography>
                    <Button color='secondary' variant='outlined' onClick={onGoBack}>
                        Go back
                    </Button>
                </Box>
                <Divider />
                <List>{renderNestedList(streamingUser)}</List>
            </CardContent>

            {streamingUser.procedures?.length === 300 ? (
                <Typography color='red' marginLeft='20px'>
                    Warning - some older data may be excluded from the table above and the graph below!
                </Typography>
            ) : null}

            {streamingUser.procedures && <ProcedurePlot data={streamingUser.procedures} />}
            {streamingUser.network && <NetworkStatisticsTable data={streamingUser.network} />}
            {streamingUser.installations && <FinalFormChart data={streamingUser.installations} />}
        </Card>
    );
});

export default function Installation() {
    const navigate = useNavigate();
    const { user } = useParams();
    const { useStreamingUser } = useInstall();
    const streamingUser = useStreamingUser(user);

    // Use a stable callback for going back
    const handleGoBack = useCallback(() => {
        navigate('/installations');
    }, [navigate]);

    return (
        <Grid item xs={12}>
            {streamingUser ? (
                <DetailedInformation streamingUser={streamingUser} onGoBack={handleGoBack} />
            ) : (
                <>
                    <Typography>User Not Found</Typography>
                    <Button color='secondary' variant='outlined' onClick={handleGoBack}>
                        Go back
                    </Button>
                </>
            )}
        </Grid>
    );
}
