import PaperCard from '../components/report/PaperCard';
import CaseAssessmentMain from '../components/assessment/CaseAssessmentMain';
import { useNavigate, useParams } from 'react-router-dom';
import CaseAssessmentSteps from '../components/assessment/CaseAssessmentSteps';
import useShow from '../aceapi/hooks/useShow';
import Forbidden from '../components/Login/Forbidden';
import { useAssessmentsCasesByProcedureId } from '../aceapi/aceComponents';
import { useEffect } from 'react';

export default function CaseAssessment(props) {
    const show = useShow();

    const { id } = useParams();

    const { data: caseData } = useAssessmentsCasesByProcedureId(
        { queryParams: { procedureId: id } },
        { enabled: id?.length === 36 },
    );

    const navigate = useNavigate();
    useEffect(() => {
        if (caseData && id?.length === 36) {
            if (caseData.id > 0) navigate(`/assessments/${caseData.id}/${id}`);
            else navigate(`/assessments`);
        }
    }, [caseData, id, navigate]);

    return !show.case_assessments ? (
        <Forbidden />
    ) : (
        <PaperCard title='Live Assessment' xs={12} variant='h3' align='center'>
            {id && id?.length !== 36 ? <CaseAssessmentSteps {...props} /> : <CaseAssessmentMain {...props} />}
        </PaperCard>
    );
}
