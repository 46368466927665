import { withAsyncBoundary } from '../../../aceapi/utils';
import MACaseSeriesList from './MACaseSeriesList';
import { Stack } from '@mui/material';
import Box from '@mui/material/Box';
import MACaseSeriesSelect from './MACaseSeriesSelect';
import { useState } from 'react';
import MaCaseSeriesNetworkStats from './MaCaseSeriesNetworkStats';
import MACaseSeriesOverviewStats from './MACaseSeriesOverviewStats';

function MACaseSeriesMetricsMain() {
    const [selectedCaseSeries, setSelectedCaseSeries] = useState('');

    return (
        <Stack spacing={2}>
            <Box width='50%'>
                <MACaseSeriesList />
            </Box>
            <MACaseSeriesSelect onSelect={setSelectedCaseSeries} value={selectedCaseSeries} />
            <Stack spacing={3}>
                {selectedCaseSeries && <MACaseSeriesOverviewStats csId={selectedCaseSeries} />}
                {selectedCaseSeries && <MaCaseSeriesNetworkStats csId={selectedCaseSeries} />}
            </Stack>
        </Stack>
    );
}

export default withAsyncBoundary(MACaseSeriesMetricsMain);
