import { useTheme } from '@mui/material';
import * as d3 from 'd3';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
    useProceduresCaqs,
    useProceduresDetections,
    useProceduresDetectionsPolygon,
    useProceduresFreezeFrames,
    useProceduresPredictions,
    useProceduresStatus,
} from '../../aceapi/aceComponents';
import useProcCaecum from '../../aceapi/hooks/useProcCaecum';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useShow from '../../aceapi/hooks/useShow';
import { AsyncBoundary } from '../../aceapi/utils';
import { useAceApp } from '../Menu/ReportAppSelector';
import { TimelinePlaceholder } from '../placeholders/placeholders';
import useExtraData from '../report/useExtraData';
import TimelineContent from './timeline/TimelineContent';
import {
    filterSmallIntervals,
    findIntervals,
    getFixedPredictions,
    predictionsToTimeline,
    statusToTimeline,
    timestampIntervalsToTimelineData,
} from './timeline/utils';

function AsyncReportTimeline(props) {
    const {
        frames,
        seeker,
        toggleBars,
        stepGrowing,
        hoveredFrame,
        loaded,
        setUnavailable,
        compact = false,
        debugCaecum = false,
        showFreezeFrames = false,
        ...rest
    } = props;

    const { app } = useAceApp();
    const { uuid } = useParams();
    const theme = useTheme();
    const show = useShow();
    const { data: predictions_raw } = useProceduresPredictions({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: detectionStatus_raw } = useProceduresStatus({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: normal_detections_raw } = useProceduresDetections({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const { data: polygon_detections_raw } = useProceduresDetectionsPolygon({
        pathParams: { procedureId: uuid },
        queryParams: { app },
    });
    const detections_raw = (polygon_detections_raw?.length ?? 0) > 0 ? polygon_detections_raw : normal_detections_raw;

    let { extraRow, extraBars } = useExtraData({ show: show.extra_models });
    if (props.extraRow) extraRow = props.extraRow;
    if (props.extraBars) extraBars = props.extraBars;

    const caecum = useProcCaecum(true, debugCaecum);
    const { data: caqData } = useProceduresCaqs({ pathParams: { procedureId: uuid } });
    const caecumTimestamp = caqData?.caecum_timestamp;

    const { data: freezeFrames } = useProceduresFreezeFrames(
        {
            pathParams: { procedureId: uuid },
            queryParams: { app },
        },
        { enabled: show.debug && showFreezeFrames },
    );

    const rawtime = useProcTime();
    const time = useMemo(() => {
        if (stepGrowing) {
            const diff = rawtime.end - rawtime.start;
            const end = rawtime.start + Math.max(Math.pow(2, Math.ceil(Math.log2(diff * 1.05))), 2 ** 18);
            return { ...rawtime, end };
        }
        return rawtime;
    }, [rawtime, stepGrowing]);

    const noData = useMemo(() => {
        return (
            (!Array.isArray(predictions_raw) || predictions_raw.length === 0) &&
            (!Array.isArray(detectionStatus_raw) || detectionStatus_raw.length === 0) &&
            (!Array.isArray(extraRow) || extraRow.length === 0)
        );
    }, [predictions_raw, detectionStatus_raw, extraRow]);

    useEffect(() => {
        if (noData && time.status !== 'STARTED')
            setUnavailable((prevState) => ({
                ...prevState,
                'No detections': 'detection was turned off or nothing was detected during this procedure',
            }));
    }, [noData, setUnavailable, time.status]);

    const detectionData = useMemo(() => {
        const intervals = d3
            .rollups(
                detections_raw,
                (v) =>
                    findIntervals(
                        v.map((x) => x.timestamp),
                        5000,
                    ),
                (d) => d.track,
            )
            .map(([, v]) => v)
            .flat();
        return timestampIntervalsToTimelineData(filterSmallIntervals(intervals, 1000), time, 'Detection');
    }, [detections_raw, time]);

    const timelineData = useMemo(() => {
        const predictions = predictionsToTimeline(predictions_raw, time);
        const detectionsStatus = statusToTimeline(detectionStatus_raw, time);
        const data = getFixedPredictions(predictions, detectionsStatus);
        const frameBars =
            toggleBars && Array.isArray(frames)
                ? frames.map((f) => (f.timestamp - time.start) / (time.end - time.start))
                : [];

        return [
            {
                group: 'Detection',
                data: [
                    {
                        label: 'Detections',
                        data: data,
                        color: theme.palette.primary.light,
                    },
                    {
                        label: 'Detection Off',
                        data: detectionsStatus ?? [],
                        color: '#e6194b',
                    },
                ],
                bars: [
                    {
                        label: 'Diagnosis',
                        data: frameBars,
                        color: '#ff00dd',
                    },
                    {
                        label: 'Caecum',
                        data: caecumTimestamp ? [(caecumTimestamp - time.start) / (time.end - time.start)] : [],
                        color: '#6cacff',
                        showLabel: true,
                    },
                    ...(Array.isArray(caecum?.debug) && caecum.debug.length > 0
                        ? [
                              {
                                  label: 'Caecum*',
                                  data: caecum.debug.map((x) => (x.timestamp - time.start) / (time.end - time.start)),
                                  color: '#6cacff',
                                  showLabel: false,
                              },
                          ]
                        : []),
                    {
                        label: 'Ongoing',
                        data: stepGrowing ? [(rawtime.end - time.start) / (time.end - time.start)] : [],
                        color: 'green',
                    },
                ],
            },
            {
                group: 'Fine Detection',
                data: [
                    {
                        label: 'Detections',
                        data: detectionData,
                        color: theme.palette.primary.light,
                    },
                ],
                bars: [
                    {
                        label: 'Freeze Frames',
                        data:
                            showFreezeFrames && Array.isArray(freezeFrames) && freezeFrames.length > 0
                                ? freezeFrames.map((x) => (x.timestamp - time.start) / (time.end - time.start))
                                : [],
                        color: '#f8660e',
                        showLabel: false,
                    },
                ],
            },
            ...((Array.isArray(extraRow) && extraRow.length > 0) || (Array.isArray(extraBars) && extraBars.length > 0)
                ? [
                      {
                          group: 'Withdrawal',
                          data: [
                              {
                                  label: 'Other',
                                  data: extraRow,
                              },
                          ],
                          bars: extraBars,
                      },
                  ]
                : []),
        ];
    }, [
        caecumTimestamp,
        caecum.debug,
        showFreezeFrames,
        freezeFrames,
        detectionStatus_raw,
        detectionData,
        extraBars,
        extraRow,
        frames,
        predictions_raw,
        rawtime.end,
        stepGrowing,
        theme.palette.primary.light,
        time,
        toggleBars,
    ]);

    useEffect(() => {
        loaded();
    }, [loaded]);

    return (
        <TimelineContent
            time={time}
            data={timelineData}
            seeker={seeker}
            frames={frames}
            hoveredFrame={hoveredFrame}
            layout={{
                padding: {
                    top: props.disablePinning ? 20 : 129,
                    right: compact ? 20 : 42,
                    bottom: 0,
                    left: compact ? 12 : 80,
                },
            }}
            {...rest}
        />
    );
}

export default function ReportTimeline(props) {
    return (
        <AsyncBoundary fallback={<TimelinePlaceholder />}>
            <AsyncReportTimeline {...props} />
        </AsyncBoundary>
    );
}
