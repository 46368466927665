import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import PaperCard from '../../components/report/PaperCard';
import { DataGrid } from '@mui/x-data-grid';
import { useState } from 'react';
import { useAceApp } from '../../components/Menu/ReportAppSelector';
import ForceSelectApp from '../../components/report/ForceSelectApp';
import useInstall from '../../aceapi/hooks/useInstall';
import { format, isBefore, subWeeks } from 'date-fns';

export default function InstallationOverview() {
    const { app } = useAceApp();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { streamingUserList, groupMetaData } = useInstall();
    const [filter, setFilter] = useState('');

    const data = streamingUserList.map((user) => {
        const obj = {
            ...user.user,
            ...user.indexed_db,
            procedureNumber: 0, // user?.procedures.length || 0,
            last_active: user?.last_procedure || 0,
            group: groupMetaData.find((s) => user.user.username.includes(s.name))?.name ?? '',
            ...user,
        };
        delete obj.user, obj.indexed_db;
        return obj;
    });

    const filterData = data.filter((d) => {
        if (!filter) {
            return d;
        }
        if (d.username.includes(filter)) {
            return d;
        }
    });

    const handleUsernameClick = (username) => {
        navigate(`${pathname.endsWith('/') ? pathname.slice(0, -1) : pathname}/${username}`);
    };

    const handleGroupClick = (group) => {
        setFilter(group);
    };

    const columns = [
        {
            field: 'username',
            headerName: 'User Name',
            width: 250,
            renderCell: (params) => {
                return <span onClick={() => handleUsernameClick(params.value)}>{params.value}</span>;
            },
        },
        { field: 'id', headerName: 'ID' },
        // { field: 'user', headerName: 'User' },
        {
            field: 'hospital_room',
            headerName: 'Hospital Acronym',
            width: 150,
            renderCell: (params) => {
                return <>{params.value?.hospital?.acronym}</>;
            },
        },
        { field: 'email', headerName: 'Email', width: 150 },
        { field: 'date_joined', headerName: 'Account created', width: 150 },
        {
            field: 'last_active',
            headerName: 'Last Active',
            width: 250,
            renderCell: (params) => {
                const WeeksAgo = subWeeks(new Date(), 2);
                const cellDate = new Date(params.value);
                const isOld = isBefore(cellDate, WeeksAgo);

                return (
                    <span style={{ color: isOld ? 'red' : 'inherit', fontWeight: isOld ? 'bold' : 'normal' }}>
                        {params.value ? format(cellDate, 'yyyy-MM-dd') : 'No Activity'}
                    </span>
                );
            },
        },
        {
            field: 'group',
            headerName: 'Group',
            width: 150,
            renderCell: (params) => {
                return <span onClick={() => handleGroupClick(params.value)}>{params.value}</span>;
            },
        },
    ];

    return (
        <PaperCard>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
                <Typography variant='h4'> List of all {app.toUpperCase()} Users</Typography>
                {filter && (
                    <Button
                        onClick={() => {
                            setFilter('');
                        }}
                    >
                        Reset
                    </Button>
                )}
            </Box>
            <ForceSelectApp>
                <DataGrid
                    columns={columns}
                    rows={filterData}
                    getRowId={(row) => row.id}
                    autoHeight
                    style={{ cursor: 'pointer', padding: '20px', backgroundColor: '#f0f0f0' }}
                />
            </ForceSelectApp>
        </PaperCard>
    );
}
