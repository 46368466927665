import { withAsyncBoundary } from '../../../aceapi/utils';
import { useCaseSeriesList } from '../../../aceapi/aceComponents';
import { MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';

function MACaseSeriesSelect({ value = '', onSelect = () => null }) {
    const { data: caseSeries } = useCaseSeriesList({});

    return (
        <Box width={300}>
            <TextField
                select
                label='Select Case Series'
                value={value}
                onChange={(e) => onSelect(e.target.value)}
                fullWidth
            >
                <MenuItem value=''>
                    <em>None</em>
                </MenuItem>
                {caseSeries?.map((cs) => (
                    <MenuItem key={cs.id} value={cs.id}>
                        {cs.name}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
}

export default withAsyncBoundary(MACaseSeriesSelect);
