import { withAsyncBoundary } from '../../aceapi/utils';
import { useParams } from 'react-router-dom';
import { useAnnotationsCasesByProcedureId } from '../../aceapi/aceComponents';
import CaseAnnotationCreate from './CaseAnnotationCreate';
import CaseAnnotationForm from './CaseAnnotationForm';

function CaseAnnotationMain() {
    const { uuid } = useParams();
    const { data: caseData } = useAnnotationsCasesByProcedureId({ queryParams: { procedureId: uuid } });

    return caseData.id ? <CaseAnnotationForm /> : <CaseAnnotationCreate />;
}

export default withAsyncBoundary(CaseAnnotationMain);
