import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import InstallationOverview from './InstallationOverview';
import Installation from './Installation';

export default function InstallationDetail() {
    const { user } = useParams();

    return <Box>{user ? <Installation /> : <InstallationOverview />}</Box>;
}
