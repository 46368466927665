import Grid from '@mui/material/Grid';
import { FieldComponent } from '../../assessment/common/AssessmentCommonFields';

export default function AnnotationCommonFields({ fields, onChange, xs = 4 }) {
    return (
        <Grid container spacing={3} sx={{ width: '100%' }}>
            {fields?.map((field) => (
                <Grid item key={field.id} xs={xs}>
                    <FieldComponent field={field} onChange={onChange} keyField='slug' />
                </Grid>
            ))}
        </Grid>
    );
}
