import AddIcon from '@mui/icons-material/Add';
import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAnnotationsCasesByProcedureId, useAnnotationsFieldsList } from '../../../aceapi/aceComponents';
import { withAsyncBoundary } from '../../../aceapi/utils';
import useCaseAnnotationMgr from '../hooks/useCaseAnnotationMgr';

import useProcTime from '../../../aceapi/hooks/useProcTime';

function AnnotationAddNewRow({ timestamp, selectedCategories }) {
    const { uuid } = useParams();
    const { data: caseData } = useAnnotationsCasesByProcedureId({ queryParams: { procedureId: uuid } });

    const { data: rowFields } = useAnnotationsFieldsList({
        queryParams: { type: 'ROW', ...(selectedCategories && { categoriesFilter: selectedCategories }) },
    });

    const { createAnnotationValue } = useCaseAnnotationMgr();
    const procedureTimeDetails = useProcTime();

    const [selectedField, setSelectedField] = useState(rowFields?.[0]?.id ?? 0);

    const videoSeekerExists = typeof timestamp === 'number';
    const videoSeekerIsOutOfProcedureBounds =
        videoSeekerExists && (timestamp < procedureTimeDetails.start || timestamp > procedureTimeDetails.end);
    const addAnnotationButtonIsDisabled = !selectedField || !videoSeekerExists || videoSeekerIsOutOfProcedureBounds;

    return (
        <Stack direction='column' spacing={1}>
            <Stack direction='row' spacing={2}>
                <TextField
                    select
                    label='Field'
                    variant='outlined'
                    size='small'
                    value={selectedField}
                    onChange={(e) => setSelectedField(e.target.value)}
                    sx={{ minWidth: 200 }}
                >
                    {rowFields?.map((field) => (
                        <MenuItem key={field.id} value={field.id}>
                            {field.name}
                        </MenuItem>
                    ))}
                </TextField>
                <Button
                    variant='contained'
                    color='primary'
                    startIcon={<AddIcon />}
                    disabled={addAnnotationButtonIsDisabled}
                    onClick={() => createAnnotationValue(caseData.id, selectedField, timestamp)}
                >
                    Add row
                </Button>
            </Stack>
            {videoSeekerIsOutOfProcedureBounds && (
                <Typography variant='caption' color='error'>
                    {"Can't add annotation outside of procedure bounds."}
                </Typography>
            )}
        </Stack>
    );
}

export default withAsyncBoundary(AnnotationAddNewRow);
