import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UpdateIcon from '@mui/icons-material/Update';
import {
    Timeline,
    TimelineConnector,
    TimelineContent,
    TimelineDot,
    TimelineItem,
    TimelineOppositeContent,
    TimelineSeparator,
} from '@mui/lab';
import { Box, Fade, IconButton, Skeleton, Stack, Tooltip } from '@mui/material';
import format from 'format-duration';
import { useState } from 'react';
import useProcTime from '../../aceapi/hooks/useProcTime';
import useProcTimeDetails from '../../aceapi/hooks/useProcTimeDetails';
import { AsyncBoundary } from '../../aceapi/utils';
import { useAceApp } from '../Menu/ReportAppSelector';
import useCaduFreezeFrame from '../report/useCaduFreezeFrame';

function AsyncTimeline({ frames, seeker, maxHeight = '32rem', startExpanded = false, fixedState = false }) {
    const { app } = useAceApp();
    const time = useProcTime();
    const { bodyEnterTimestamp, bodyExitTimestamp, caecumTimeRelativeToProcedureStart, retroflexionTimestamp } =
        useProcTimeDetails();

    const [compact, setCompact] = useState(!startExpanded);

    const start = time.start;
    const end = time.internalEnd;
    // const sizes = ["<5mm", "5-\u206010mm", ">10mm"];

    const charName = app.toLowerCase() === 'smartibd' ? 'Score' : 'Polyp';

    const polyps = Array.isArray(frames)
        ? frames
              .filter((x) => x.frame_id && x.diagnosis.toLowerCase() !== 'uncertain')
              .map((x) => ({
                  // name: `Polyp (${x.diagnosis}) ${sizes[Math.floor(Math.random() * sizes.length)]}`,
                  name: `${charName} (${x.diagnosis})`,
                  time: x.timestamp - start,
                  color: x.diagnosis.toLowerCase() === 'adenoma' ? 'error' : 'warning',
              }))
        : [];

    const counts = [
        {
            type: 'Adenoma',
            count: polyps.filter((x) => x.name.toLowerCase() === 'polyp (adenoma)').length,
            color: 'error',
        },
        {
            type: 'Non-Adenoma',
            count: polyps.filter((x) => x.name.toLowerCase() === 'polyp (non-adenoma)').length,
            color: 'warning',
        },
    ].map((x) => ({
        name: `Total ${x.type}`,
        time: `#${x.count}`,
        color: x.color,
    }));

    const freezeFrames = useCaduFreezeFrame();

    let events = [
        { name: 'Start', time: 0, color: 'secondary' },
        ...(bodyEnterTimestamp
            ? [
                  {
                      name: 'Body start',
                      time: bodyEnterTimestamp - start,
                      color: 'info',
                  },
              ]
            : []),
        ...(caecumTimeRelativeToProcedureStart
            ? [{ name: 'Caecum', time: caecumTimeRelativeToProcedureStart, color: 'success' }]
            : []),
        ...(compact ? counts : polyps),
        ...(retroflexionTimestamp
            ? [{ name: 'Retroflexion', time: retroflexionTimestamp - start, color: 'primary' }]
            : []),
        ...(freezeFrames?.map((x) => ({ name: 'Dysplasia Frame', time: x - start, color: 'info' })) ?? []),
        { name: 'End', time: end - start, color: 'secondary' },
        ...(bodyExitTimestamp
            ? [
                  {
                      name: 'Body end',
                      time: bodyExitTimestamp - start,
                      color: 'info',
                  },
              ]
            : []),
    ];
    if (!compact) events = events.sort((a, b) => a.time - b.time);

    const seekable = seeker.playerRef?.state !== null;
    const [hover, setHover] = useState(null);

    const handleClick = (event) => {
        seeker.seekTo(event.time + start);
    };

    return (
        <Stack spacing={compact && !fixedState ? -5 : 0}>
            <Timeline position='alternate' style={{ overflowY: 'auto', maxHeight }}>
                {events.map((event, i) => (
                    <TimelineItem key={i} onMouseOver={() => setHover(i)} onMouseOut={() => setHover(null)}>
                        <TimelineOppositeContent color='text.secondary'>
                            {compact && event.name.startsWith('Total') ? event.time : format(event.time)}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot color={event.color} sx={{ p: seekable ? 0 : '4px' }}>
                                {seekable && (
                                    <IconButton
                                        size='small'
                                        onClick={() => handleClick(event)}
                                        sx={{ height: '1rem', width: '1rem' }}
                                    >
                                        <Fade in={hover === i}>
                                            <UpdateIcon sx={{ color: 'white' }} />
                                        </Fade>
                                    </IconButton>
                                )}
                            </TimelineDot>
                            {i < events.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent color='primary.dark'>
                            <b>{event.name}</b>
                        </TimelineContent>
                    </TimelineItem>
                ))}
            </Timeline>
            {!fixedState && (
                <Box display='flex' justifyContent='center' width='100%'>
                    <Tooltip title={compact ? 'Show more details' : 'Show less details'}>
                        <IconButton onClick={() => setCompact((prevState) => !prevState)}>
                            {compact ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            )}
        </Stack>
    );
}

export default function EventTimeline(props) {
    return (
        <AsyncBoundary
            fallback={
                <Stack p={2} spacing={1}>
                    {Array(10)
                        .fill()
                        .map((_, i) => (
                            <Stack key={i} direction='row' spacing={1} alignItems='center'>
                                {i % 2 === 0 && (
                                    <Skeleton variant='rounded' width={163.35} height={24} animation='wave' />
                                )}
                                {i % 2 === 1 && <Box width={163.35} />}
                                <Skeleton variant='circular' width={48} height={48} animation='wave' />
                                {i % 2 === 1 && (
                                    <Skeleton variant='rounded' width={163.35} height={24} animation='wave' />
                                )}
                            </Stack>
                        ))}
                </Stack>
            }
        >
            <AsyncTimeline {...props} />
        </AsyncBoundary>
    );
}
